import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import type { DatePickerValueType } from "@components/DatePicker/DatePicker";
import type { FilterType } from "@components/Filters/Filters";
import { FilterEnum } from "@constants";
import { getQueryParamsCount } from "@helpers/getQueryParamsCount";
import { useQuery } from "@tanstack/react-query";

import { getDeviceDetailsReportingFilter } from "../api/getDeviceDetailsReportingFilter";

export const useDeviceDetailsReportingFilter = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(0);
	const [selectedIMEI, setSelectedIMEI] = useState<string[]>(
		searchParams.get("imei")?.split(",") || [],
	);
	const [selectedDevices, setSelectedDevices] = useState<string[]>(
		searchParams.get("device")?.split(",") || [],
	);
	const [selectedMemoryCapacity, setSelectedMemoryCapacity] = useState<string[]>(
		searchParams.get("memorycapacity")?.split(",") || [],
	);
	const [selectedOSVersion, setSelectedOSVersion] = useState<string[]>(
		searchParams.get("osversion")?.split(",") || [],
	);
	const [selectedStatus, setSelectedStatus] = useState<string[]>(
		searchParams.get("status")?.split(",") || [],
	);

	const queryString = useMemo(() => {
		const params = new URLSearchParams(location.search);

		if (selectedIMEI.length > 0) {
			params.set("imei", selectedIMEI.join(","));
		} else {
			params.delete("imei");
		}

		if (selectedDevices.length > 0) {
			params.set("device", selectedDevices.join(","));
		} else {
			params.delete("device");
		}

		if (selectedMemoryCapacity.length > 0) {
			params.set("memorycapacity", selectedMemoryCapacity.join(","));
		} else {
			params.delete("memorycapacity");
		}

		if (selectedOSVersion.length > 0) {
			params.set("osversion", selectedOSVersion.join(","));
		} else {
			params.delete("osversion");
		}

		if (selectedStatus.length > 0) {
			params.set("status", selectedStatus.join(","));
		} else {
			params.delete("status");
		}

		return decodeURIComponent(params.toString());
	}, [
		location.search,
		selectedDevices,
		selectedIMEI,
		selectedMemoryCapacity,
		selectedOSVersion,
		selectedStatus,
	]);

	const onIMEIClear = () => setSelectedIMEI([]);
	const onDevicesClear = () => setSelectedDevices([]);
	const onMemoryCapacityClear = () => setSelectedMemoryCapacity([]);
	const onOSVersionClear = () => setSelectedOSVersion([]);
	const onStatusClear = () => setSelectedStatus([]);

	const onClearAllFilters = () => {
		setSelectedIMEI([]);
		setSelectedDevices([]);
		setSelectedMemoryCapacity([]);
		setSelectedOSVersion([]);
		setSelectedStatus([]);
	};

	const { data, isLoading, refetch } = useQuery({
		queryKey: ["deviceDetailsReportingFilter"],
		queryFn: () =>
			getDeviceDetailsReportingFilter({
				imei: selectedIMEI.join(","),
			}),
		enabled: true,
	});

	useEffect(() => {
		navigate(`${location.pathname}?${queryString}`, { replace: true });
		refetch();
		setSelectedFiltersCount(
			getQueryParamsCount(["imei", "device", "osversion", "memorycapacity", "status"]),
		);
	}, [queryString, navigate, location.pathname, refetch]);

	const filters: (FilterType<string[]> | FilterType<DatePickerValueType>)[] = [
		{
			filterId: "imei",
			placeholder: "Select IMEI",
			searchPlaceholder: "Enter text",
			isMultiple: true,
			options: data?.data.imei || [],
			value: selectedIMEI,
			onChange: setSelectedIMEI,
			onClear: onIMEIClear,
			type: FilterEnum.DROPDOWN,
		},
		{
			filterId: "device",
			placeholder: "Select Device",
			isMultiple: true,
			options: data?.data.device_names || [],
			value: selectedDevices,
			onChange: setSelectedDevices,
			onClear: onDevicesClear,
			type: FilterEnum.DROPDOWN,
		},
		{
			filterId: "osversion",
			placeholder: "Select OS version",
			isMultiple: true,
			options: data?.data.os_versions || [],
			value: selectedOSVersion,
			onChange: setSelectedOSVersion,
			onClear: onOSVersionClear,
			type: FilterEnum.DROPDOWN,
		},
		{
			filterId: "memorycapacity",
			placeholder: "Select Memory capacity",
			isMultiple: true,
			options: data?.data.memory_capacities || [],
			value: selectedMemoryCapacity,
			onChange: setSelectedMemoryCapacity,
			onClear: onMemoryCapacityClear,
			type: FilterEnum.DROPDOWN,
		},
		{
			filterId: "status",
			placeholder: "Select Status",
			isMultiple: true,
			options: data?.data.status || [],
			value: selectedStatus,
			onChange: setSelectedStatus,
			onClear: onStatusClear,
			type: FilterEnum.DROPDOWN,
		},
	];

	return {
		isLoading,
		filters,
		onClearAllFilters,
		selectedFiltersCount,
	};
};
