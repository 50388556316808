import dayjs from "dayjs";

import type { Value } from "@wojtekmaj/react-daterange-picker/dist/cjs/shared/types";

import { formatDate } from "./formatDate";

export const getDateRangeLabel = (dateRange: Value, timeRange: [string, string] | null) => {
	if (!dateRange || !Array.isArray(dateRange) || dateRange.length !== 2) {
		return "";
	}

	const [startDate, endDate] = dateRange;
	const now = dayjs();
	const start = dayjs(startDate);
	const end = dayjs(endDate);

	if (start.isSame(now, "day") && end.isSame(now, "day")) {
		return "Today";
	}
	if (start.isSame(now.subtract(1, "day"), "day") && end.isSame(now.subtract(1, "day"), "day")) {
		return "Yesterday";
	}
	if (start.isSame(now.subtract(6, "day"), "day") && end.isSame(now, "day")) {
		return "Last 7 days";
	}
	if (start.isSame(now.subtract(29, "day"), "day") && end.isSame(now, "day")) {
		return "Last 30 days";
	}
	if (start.isSame(now.startOf("month")) && end.isSame(now, "day")) {
		return "This month";
	}
	if (
		start.isSame(now.subtract(1, "month").startOf("month")) &&
		end.isSame(now.subtract(1, "month").endOf("month"))
	) {
		return "Last month";
	}
	if (
		start.isSame(now.subtract(1, "year").startOf("year")) &&
		end.isSame(now.subtract(1, "year").endOf("year"))
	) {
		return "Last year";
	}

	return `${formatDate(startDate)}${timeRange ? " " + timeRange[0] : ""} - ${formatDate(endDate)}${timeRange ? " " + timeRange[1] : ""}`;
};
