import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import Box from "@mui/material/Box";

export const StyledPanelHeader = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		.panel-header__top {
			min-height: 40px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.panel-header__top--title {
			display: flex;
			flex-shrink: 0;
		}

		.panel-header__top--title .MuiTypography-root {
			color: ${theme?.palette?.customColors.black100};
			font-weight: ${theme?.customTypography?.fontWeight.regular};
			font-size: ${theme?.customTypography?.fontSize.fs34};
			line-height: ${theme?.customTypography?.lineHeight.lh47};
			letter-spacing: ${theme?.customTypography?.letterSpacing.ls06};
		}
	`}
`;
