import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { Filters } from "@components/Filters/Filters";
import { ToasterMessage } from "@components/ToasterMessage/ToasterMessage";
import { DeviceStatusEnum } from "@constants";
import { useLocationTrackingCSVExport } from "@hooks/useLocationTrackingCSVExport";
import { useLocationTrackingFilter } from "@hooks/useLocationTrackingFilter";
import { useLocationTrackingList } from "@hooks/useLocationTrackingList";
import { DashboardLayout } from "@layout/DashboardLayout/DashboardLayout";
import { Devices } from "@pages/LocationTracking/Devices/Devices";
import { LocationTrackingMap } from "@pages/LocationTracking/LocationTrackingMap/LocationTrackingMap";
import { PanelHeader } from "@pages/LocationTracking/PanelHeader/PanelHeader";
import type { LocationTrackingDeviceType } from "@type/LocationTrackingTypes";

const INITIAL_MAP_CENTER: google.maps.LatLngLiteral = {
	lat: 34.44706163603654,
	lng: 9.699379114536555,
};

const INITIAL_MAP_ZOOM: number = 3;
const MAP_ZOOM_ON_DEVICE_SELECT = 16;

export const LocationTracking = () => {
	const devicesScrollBarRef = useRef<HTMLDivElement | null>(null);
	const { isPending, isError, onCSVDownload } = useLocationTrackingCSVExport();
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const { filters, selectedFiltersCount, onClearAllFilters } = useLocationTrackingFilter();
	const { data } = useLocationTrackingList();
	const [isDeviceListOpen, setIsDeviceListOpen] = useState<boolean>(false);
	const [topOffset, setTopOffset] = useState<number>(128);
	const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>(INITIAL_MAP_CENTER);
	const [mapZoom, setMapZoom] = useState<number>(INITIAL_MAP_ZOOM);
	const [selectedDevice, setSelectedDevice] = useState<LocationTrackingDeviceType | null>(null);
	const [openDevice, setOpenDevice] = useState<string>("");
	const [isProgrammaticInteraction, setIsProgrammaticInteraction] = useState(false);

	const [isSidebarOpen, setIsSidebarOpen] = useState(true);

	const onFilterToggle = () => setIsFilterOpen((prev) => !prev);

	const onDeviceListVisibilityToggle = () => setIsDeviceListOpen((prev) => !prev);

	const onScrollDevicesListToDeviceId = (id: string) => {
		if (devicesScrollBarRef.current) {
			//@ts-ignore
			devicesScrollBarRef.current.scrollToId(id);
		}
	};

	const onDeviceChange = (device: LocationTrackingDeviceType) => {
		const deviceIMEI = device?.imei || "";
		const isDeviceMovingStatus = device.status === DeviceStatusEnum.MOVING;

		if (deviceIMEI !== selectedDevice?.imei) {
			setSelectedDevice(device);
			setOpenDevice("");
		}

		if (!isDeviceMovingStatus) {
			setOpenDevice((prev) => (prev === deviceIMEI ? deviceIMEI : ""));
		}

		if (device) {
			setMapCenter({ lat: device.lat, lng: device.lng });
			setMapZoom(MAP_ZOOM_ON_DEVICE_SELECT);
			setIsProgrammaticInteraction(true);
		}
	};

	const onSelectMapDevice = (device: LocationTrackingDeviceType) => {
		const deviceIMEI = device.imei;
		const isDeviceMovingStatus = device.status === DeviceStatusEnum.MOVING;
		deviceIMEI !== selectedDevice?.imei && setSelectedDevice(device);

		if (isDeviceMovingStatus) {
			setOpenDevice((prev) => (prev === deviceIMEI ? "" : deviceIMEI));
		} else {
			setOpenDevice("");
		}

		if (device) {
			const deviceId = `device-id_${device.imei}`;

			if (!isDeviceListOpen) {
				setIsDeviceListOpen(true);
				setTimeout(() => {
					onScrollDevicesListToDeviceId(deviceId);
				}, 1);
			} else {
				onScrollDevicesListToDeviceId(deviceId);
			}
		}
	};

	useEffect(() => {
		const deviceInData = data.find(
			(device: LocationTrackingDeviceType) => device.imei === selectedDevice?.imei,
		);

		if (!deviceInData) {
			setSelectedDevice(null);
			setOpenDevice("");
		} else {
			setSelectedDevice(deviceInData);
			const deviceId = `device-id_${deviceInData.imei}`;

			if (!isDeviceListOpen) {
				setIsDeviceListOpen(true);
				setTimeout(() => {
					onScrollDevicesListToDeviceId(deviceId);
				}, 1);
			} else {
				onScrollDevicesListToDeviceId(deviceId);
			}
		}
	}, [data, selectedDevice, isDeviceListOpen]);

	useEffect(() => {
		if (isError) {
			toast.error(<ToasterMessage title="Something went wrong" message="" />);
		}
	}, [isError]);

	return (
		<DashboardLayout
			containerOffset={0}
			isHeaderHidden={true}
			isSidebarOpen={isSidebarOpen}
			setIsSidebarOpen={setIsSidebarOpen}
		>
			<PanelHeader
				setTopOffset={setTopOffset}
				isFilterOpen={isFilterOpen}
				isSidebarOpen={isSidebarOpen}
				onFilterToggle={onFilterToggle}
				filters={<Filters isOpen={isFilterOpen} filters={filters} onClearAll={onClearAllFilters} />}
				selectedFilterCount={selectedFiltersCount}
				isCSVExportPending={isPending}
				onExportToCSV={onCSVDownload}
			/>
			<Devices
				isOpen={isDeviceListOpen}
				onToggle={onDeviceListVisibilityToggle}
				devicesScrollBarRef={devicesScrollBarRef}
				devices={data}
				isFilterOpen={isFilterOpen}
				topOffset={topOffset}
				selectedDevice={selectedDevice}
				onDeviceChange={onDeviceChange}
			/>
			<LocationTrackingMap
				initialMapCenter={INITIAL_MAP_CENTER}
				initialMapZoom={INITIAL_MAP_ZOOM}
				mapCenter={mapCenter}
				mapZoom={mapZoom}
				devices={data}
				openDevice={openDevice}
				selectedDevice={selectedDevice}
				onSelectMapDevice={onSelectMapDevice}
				isProgrammaticInteraction={isProgrammaticInteraction}
				setIsProgrammaticInteraction={setIsProgrammaticInteraction}
			/>
		</DashboardLayout>
	);
};
