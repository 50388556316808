import { useNavigate } from "react-router-dom";

import { Title } from "@components/Title/Title";
import { DASHBOARD_HOME_PAGE } from "@constants";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { StyledPageNotFound } from "@pages/PageNotFound/StyledPageNotFound";

const TITLE = "Page Not Found";
const CONTENT =
	"Unfortunately, the page you are looking for does not exist. It may have been deleted, moved, or never existed.";

export const PageNotFound = () => {
	const navigate = useNavigate();

	const onClick = () => {
		navigate(DASHBOARD_HOME_PAGE);
	};

	return (
		<StyledPageNotFound>
			<div className="page-container">
				<Title innerClassName="title" mb={5} variant="h1" title={TITLE} />
				<Typography className="content">{CONTENT}</Typography>
				<Box mt={5}>
					<Button variant="contained" color="primary" onClick={onClick}>
						Back to Home
					</Button>
				</Box>
			</div>
		</StyledPageNotFound>
	);
};
