import { type ChangeEvent } from "react";

import { StyledSearch } from "@components/Search/StyledSearch";
import SearchIcon from "@icons/SearchIcon";

type SearchProp = {
	value: string;
	placeholder?: string;
	maxLength?: number;
	onChange: (value: string) => void;
};

export const Search = ({ value, placeholder = "Search", maxLength, onChange }: SearchProp) => {
	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		onChange(value);
	};

	return (
		<StyledSearch
			className="select__search"
			placeholder={placeholder}
			InputProps={{
				endAdornment: <SearchIcon className="select__search--icon" />,
			}}
			value={value}
			inputProps={{
				maxLength: maxLength,
			}}
			onChange={handleOnChange}
		/>
	);
};
