import { useRef } from "react";

import { Select } from "@components/CustomSelect/Select";
import { CustomSelectOption } from "@components/CustomSelectOption/CustomSelectOption";
import type { DatePickerValueType } from "@components/DatePicker/DatePicker";
import { DatePicker } from "@components/DatePicker/DatePicker";
import { StyledFilters } from "@components/Filters/StyledFilters";
import { FilterEnum } from "@constants";
import CloseIcon from "@icons/CloseIcon";
import { Button } from "@mui/material";

export type FilterType<T> = {
	filterId: string;
	type: FilterEnum;
	placeholder: string;
	searchPlaceholder?: string;
	isMultiple?: boolean;
	options?: string[];
	value: string[] | DatePickerValueType;
	onChange: (value: T) => void;
	onClear?: () => void;
};

type FiltersProps = {
	isOpen: boolean;
	filters: (FilterType<string[]> | FilterType<DatePickerValueType>)[];
	onClearAll: () => void;
};

export const Filters = ({ isOpen, filters, onClearAll }: FiltersProps) => {
	const datePickerRef = useRef<{ clear: () => void } | null>(null);

	const handleClearAll = () => {
		if (datePickerRef.current) {
			datePickerRef.current.clear();
		}
		onClearAll();
	};

	return (
		<StyledFilters display={isOpen ? "flex" : "none"} flex={1} gap={2}>
			{/* eslint-disable-next-line array-callback-return */}
			{filters.map((filter) => {
				if (filter.type === FilterEnum.DROPDOWN) {
					const values = filter.value as string[];
					const valuesQty = values && values.length > 0 ? values.length : undefined;

					return (
						<Select
							key={filter.filterId}
							className="filter__item"
							placeholder={filter.placeholder}
							searchPlaceholder={filter.searchPlaceholder}
							isMultiple={filter.isMultiple}
							options={filter.options || []}
							renderItem={CustomSelectOption}
							selectedItemsQty={valuesQty}
							value={values}
							onChange={filter.onChange as (val: string[]) => void}
							onClear={filter?.onClear}
							isError={false}
						/>
					);
				} else if (
					filter.type === FilterEnum.DATA_PICKER ||
					filter.type === FilterEnum.DATA_PICKER_DATE_AND_TIME
				) {
					return (
						<DatePicker
							key={filter.filterId}
							ref={datePickerRef}
							className="filter__item"
							placeholder={filter.placeholder}
							value={filter.value as DatePickerValueType}
							onChange={filter.onChange as (val: DatePickerValueType) => void}
							isTimeRange={filter.type === FilterEnum.DATA_PICKER_DATE_AND_TIME}
						/>
					);
				}
			})}
			<Button disableRipple startIcon={<CloseIcon />} onClick={handleClearAll}>
				Clear all
			</Button>
		</StyledFilters>
	);
};
