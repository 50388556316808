import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledLoadingSpinner = styled.div`
	${({ theme }: { theme?: Theme }) => css`
		display: inline-block;
		position: relative;
		width: 24px;
		height: 24px;
		color: ${theme?.palette?.customColors.grey600};

		div {
			box-sizing: border-box;
			display: block;
			position: absolute;
			width: 16px;
			height: 16px;
			margin: 4px;
			border: 2px solid currentColor;
			border-radius: 50%;
			animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: currentColor transparent transparent transparent;
		}

		div:nth-child(1) {
			animation-delay: -0.45s;
		}

		div:nth-child(2) {
			animation-delay: -0.3s;
		}

		div:nth-child(3) {
			animation-delay: -0.15s;
		}

		@keyframes lds-ring {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	`}
`;
