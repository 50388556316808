import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledToggle = styled.button`
	${({ theme }: { theme?: Theme }) => css`
		width: 100%;
		height: 34px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0;
		border: none;
		font-weight: ${theme?.customTypography?.fontWeight.regular};
		font-size: ${theme?.customTypography?.fontSize.fs24};
		line-height: ${theme?.customTypography?.lineHeight.lh33};
		letter-spacing: ${theme?.customTypography?.letterSpacing.ls06};
		background-color: ${theme?.palette?.customColors.white};
		cursor: pointer;

		&.isOpen .toggle__icon {
			transform: rotate(0deg);
		}

		.toggle__label {
			flex: 1;
			display: flex;
			align-items: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
			line-height: 1;
		}

		.toggle__icon-wrapper {
			flex-shrink: 0;
			display: flex;
			align-items: center;
		}

		.toggle__icon {
			transform: rotate(180deg);
		}
	`}
`;
