import type { SVGProps } from "react";

const StartIcon = ({ width = "20", height = "20", ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			d="M5 5.83301V14.1663"
			stroke="black"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.1902 4.38974C14.5181 4.13088 15 4.36443 15 4.78218V15.2187C15 15.6364 14.5181 15.8699 14.1902 15.6111L7.58042 10.3928C7.32683 10.1927 7.32683 9.80816 7.58042 9.60799L14.1902 4.38974Z"
			stroke="black"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default StartIcon;
