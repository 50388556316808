import SimpleBarReact from "simplebar-react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledScrollBar = styled(SimpleBarReact)`
	${({ theme }: { theme?: Theme }) => css`
		&.isTrackBarTransparent .simplebar-track {
			background: transparent;
		}

		.simplebar-track {
			border-radius: 8px;
			background: ${theme?.palette?.customColors.grey100};

			&.simplebar-vertical {
				width: 15px;
			}
		}

		.simplebar-scrollbar:before {
			top: 4px;
			bottom: 6px;
			left: 50%;
			right: 0;
			border-radius: 8px;
			background: ${theme?.palette?.customColors.grey300};
			opacity: 1;
			transform: translateX(-50%);
		}
	`}
`;
