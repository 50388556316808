import type { ReactNode } from "react";

import Box from "@mui/material/Box";

import { StyledToasterMessage } from "./StyledToasterMessage";

type ToasterMessageProps = {
	title: string;
	message: ReactNode;
};

export const ToasterMessage = ({ title, message }: ToasterMessageProps) => {
	const isMessageExist = !!message;

	return (
		<StyledToasterMessage>
			<Box className="toaster__title" mb={isMessageExist ? 0.5 : 0}>
				{title}
			</Box>
			{isMessageExist && <div className="toaster__message">{message}</div>}
		</StyledToasterMessage>
	);
};
