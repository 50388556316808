import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledPagination = styled.div`
	${({ theme }: { theme?: Theme }) => css`
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 6px;
		width: 100%;
		background-color: ${theme?.palette?.customColors.grey100};
		padding: 16px 0px;
	`}
`;
