import { useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { LoadingStatusEnum, LOGIN_SUCCESS_REDIRECT_PATH } from "@constants";
import { useUser } from "@store/userContext";

export const GuestRoute = () => {
	const { isAuth, sessionCheckLoadingStatus } = useUser();
	const location = useLocation();

	const navigateState = useMemo(() => ({ from: location }), [location]);

	if (
		sessionCheckLoadingStatus === LoadingStatusEnum.INITIAL ||
		sessionCheckLoadingStatus === LoadingStatusEnum.LOADING
	) {
		return null;
	}

	return !isAuth ? (
		<Outlet />
	) : (
		<Navigate to={LOGIN_SUCCESS_REDIRECT_PATH} state={navigateState} replace />
	);
};
