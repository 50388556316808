import type { ICellRendererParams } from "ag-grid-community";

import { StatusActiveIcon, StatusInactiveIcon } from "@icons/index";

import { StyledStatusRenderer } from "./StyledStatusRenderer";

export const StatusRenderer = (props: ICellRendererParams) => (
	<StyledStatusRenderer>
		{props.value === "Active" && <StatusActiveIcon />}
		{props.value === "Inactive" && <StatusInactiveIcon />}
		{props.value}
	</StyledStatusRenderer>
);
