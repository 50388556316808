import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import type { DatePickerValueType } from "@components/DatePicker/DatePicker";
import type { FilterType } from "@components/Filters/Filters";
import { FilterEnum } from "@constants";
import { DayBoundaryEnum, getFormattedDateToUTCWithTimezone } from "@helpers/getFormattedDate";
import { getQueryParamsCount } from "@helpers/getQueryParamsCount";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getUserActivityTrackingFilter } from "../api/getUserActivityTrackingFilter";

export const useUserActivityTrackingFilter = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const queryClient = useQueryClient();
	const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(0);
	const [selectedIMEI, setSelectedIMEI] = useState<string[]>(
		searchParams.get("imei")?.split(",") || [],
	);
	const [selectedDevices, setSelectedDevices] = useState<string[]>(
		searchParams.get("device")?.split(",") || [],
	);
	const [selectedCountries, setSelectedCountries] = useState<string[]>(
		searchParams.get("country")?.split(",") || [],
	);
	const [selectedApplications, setSelectedApplications] = useState<string[]>(
		searchParams.get("application")?.split(",") || [],
	);
	const [selectedDate, setSelectedDate] = useState<DatePickerValueType>(
		searchParams.get("date-from") && searchParams.get("date-to")
			? { dateFrom: searchParams.get("date-from")!, dateTo: searchParams.get("date-to")! }
			: null,
	);

	const queryString = useMemo(() => {
		const params = new URLSearchParams(location.search);

		if (selectedIMEI.length > 0) {
			params.set("imei", selectedIMEI.join(","));
		} else {
			params.delete("imei");
		}

		if (selectedDevices.length > 0) {
			params.set("device", selectedDevices.join(","));
		} else {
			params.delete("device");
		}

		if (selectedCountries.length > 0) {
			params.set("country", selectedCountries.join(","));
		} else {
			params.delete("country");
		}

		if (selectedApplications.length > 0) {
			params.set("application", selectedApplications.join(","));
		} else {
			params.delete("application");
		}

		if (selectedDate) {
			params.set("date-from", selectedDate.dateFrom);
			params.set("date-to", selectedDate.dateTo);
		} else {
			params.delete("date-from");
			params.delete("date-to");
		}

		return decodeURIComponent(params.toString());
	}, [
		location.search,
		selectedIMEI,
		selectedDevices,
		selectedCountries,
		selectedApplications,
		selectedDate,
	]);

	const onIMEIClear = () => setSelectedIMEI([]);
	const onDevicesClear = () => setSelectedDevices([]);
	const onCountriesClear = () => setSelectedCountries([]);
	const onApplicationsClear = () => setSelectedApplications([]);

	const onClearAllFilters = () => {
		setSelectedIMEI([]);
		setSelectedDevices([]);
		setSelectedCountries([]);
		setSelectedApplications([]);
		setSelectedDate(null);
	};

	const { data, refetch, isLoading } = useQuery({
		queryKey: ["userActivityTrackingFilter"],
		queryFn: () => {
			const formattedDateFrom = selectedDate?.dateFrom
				? getFormattedDateToUTCWithTimezone(selectedDate?.dateFrom, DayBoundaryEnum.DAY_START)
				: undefined;
			const formattedDateTo = selectedDate?.dateTo
				? getFormattedDateToUTCWithTimezone(selectedDate?.dateTo, DayBoundaryEnum.DAY_END)
				: undefined;
			return getUserActivityTrackingFilter({
				imei: selectedIMEI.join(","),
				datafrom: formattedDateFrom,
				datato: formattedDateTo,
			});
		},
		enabled: true,
	});

	useEffect(() => {
		navigate(`${location.pathname}?${queryString}`, { replace: true });
		refetch();
		queryClient.invalidateQueries({
			queryKey: ["userActivityTrackingList"],
		});
		setSelectedFiltersCount(
			getQueryParamsCount(["imei", "device", "country", "date-from", "application"]),
		);
	}, [queryString, navigate, location.pathname, refetch, queryClient]);

	const filters: (FilterType<string[]> | FilterType<DatePickerValueType>)[] = [
		{
			filterId: "imei",
			placeholder: "Select IMEI",
			searchPlaceholder: "Enter text",
			isMultiple: true,
			options: data?.data.imei || [],
			value: selectedIMEI,
			onChange: setSelectedIMEI,
			onClear: onIMEIClear,
			type: FilterEnum.DROPDOWN,
		},
		{
			filterId: "device",
			placeholder: "Select Device",
			isMultiple: true,
			options: data?.data.device_names || [],
			value: selectedDevices,
			onChange: setSelectedDevices,
			onClear: onDevicesClear,
			type: FilterEnum.DROPDOWN,
		},
		{
			filterId: "country",
			placeholder: "Select Country",
			isMultiple: true,
			options: data?.data.coutries || [],
			value: selectedCountries,
			onChange: setSelectedCountries,
			onClear: onCountriesClear,
			type: FilterEnum.DROPDOWN,
		},
		{
			filterId: "date",
			placeholder: "Select Date",
			isMultiple: false,
			value: selectedDate,
			onChange: setSelectedDate,
			type: FilterEnum.DATA_PICKER,
		},
		{
			filterId: "application",
			placeholder: "Select Application",
			isMultiple: true,
			options: data?.data.applications || [],
			value: selectedApplications,
			onChange: setSelectedApplications,
			onClear: onApplicationsClear,
			type: FilterEnum.DROPDOWN,
		},
	];

	return {
		isLoading,
		filters,
		onClearAllFilters,
		selectedFiltersCount,
	};
};
