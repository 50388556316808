import cx from "classnames";
import dayjs from "dayjs";

import { Tooltip } from "@components/Tooltip/Tooltip";
import { DeviceStatusEnum } from "@constants";
import { InactiveStatusIcon, OfflineStatusIcon, OnlineStatusIcon } from "@icons/index";
import { Box } from "@mui/material";
import { StyledDeviceItem } from "@pages/LocationTracking/Devices/DeviceItem/StyledDeviceItem";
import type { LocationTrackingDeviceType } from "@type/LocationTrackingTypes";

type DeviceItemProps = {
	selectedDevice: LocationTrackingDeviceType | null;
	onDeviceChange: (val: LocationTrackingDeviceType) => void;
} & LocationTrackingDeviceType;

export const DeviceItem = ({
	uuid,
	imei,
	device_name,
	address,
	updated_at,
	status,
	lng,
	lat,
	prevLocations,
	selectedDevice,
	onDeviceChange,
}: DeviceItemProps) => {
	const deviceId = `device-id_${imei}`;
	const isMovingStatus = status === DeviceStatusEnum.MOVING;
	const isInactiveStatus = status === DeviceStatusEnum.INACTIVE;
	const isActiveStatus = status === DeviceStatusEnum.ACTIVE;
	const formattedDate = dayjs(updated_at).utc().format("hh:mm:ss A DD/MM/YYYY");

	const currentDevice = {
		uuid,
		imei,
		device_name,
		address,
		updated_at,
		status,
		lng,
		lat,
		prevLocations,
	};

	return (
		<StyledDeviceItem
			id={deviceId}
			className={cx({
				isSelected: imei === selectedDevice?.imei,
				isMovingStatus,
				isInactiveStatus,
				isActiveStatus,
			})}
			onClick={() => onDeviceChange(currentDevice)}
			p={2}
		>
			<Box display="flex" alignItems="center">
				<Box display="inline-flex" flexShrink={0} ml={-1}>
					{isMovingStatus && <OfflineStatusIcon />}
					{isInactiveStatus && <InactiveStatusIcon />}
					{isActiveStatus && <OnlineStatusIcon />}
				</Box>
				<Box className="device__status--label" ml={1}>
					{isActiveStatus && <>Active</>}
					{isMovingStatus && <>Location is changing</>}
					{isInactiveStatus && <>Inactive</>}
				</Box>
			</Box>
			<Box className="device" py={1}>
				<div className="device__imei">{imei || "—"}</div>
				<div className="device__name">{device_name}</div>
			</Box>
			<Box mt={1}>
				<div className="device__info--label">Address:</div>
				<Box className="device__info--content" mt={0.25}>
					{address}
				</Box>
			</Box>
			<Box mt={1}>
				<div className="device__info--label">Last update:</div>
				<Box className="device__info--content" mt={0.25}>
					<Tooltip title="Device local time is displayed" isDarkMode={true}>
						<span>{formattedDate}</span>
					</Tooltip>
				</Box>
			</Box>
		</StyledDeviceItem>
	);
};
