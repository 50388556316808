import { useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { LoadingStatusEnum, LOGIN_PAGE_PATH } from "@constants";
import { useUser } from "@store/userContext";

export const RequireAuth = () => {
	const { isAuth, sessionCheckLoadingStatus } = useUser();
	const location = useLocation();
	const navigateState = useMemo(() => ({ from: location }), [location]);

	if (
		sessionCheckLoadingStatus === LoadingStatusEnum.INITIAL ||
		sessionCheckLoadingStatus === LoadingStatusEnum.LOADING
	) {
		return null;
	}

	if (isAuth) {
		return <Outlet />;
	}

	return <Navigate to={LOGIN_PAGE_PATH} state={navigateState} replace />;
};
