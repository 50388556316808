import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

export const StyledInputLabel = styled(InputLabel)`
	${({ theme }: { theme?: Theme }) => css`
		max-width: 100%;
		margin-bottom: 4px;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		font-weight: ${theme?.customTypography?.fontWeight.regular};
	`}
`;
