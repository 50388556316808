export const getQueryParamsCount = (includeKeys: string[] = []): number => {
	const searchParams = new URLSearchParams(window.location.search);

	let count = 0;

	searchParams.forEach((_, key) => {
		if (includeKeys.includes(key)) {
			count++;
		}
	});

	return count;
};
