import { axiosInstance } from "@services/axios";

type GetDeviceDetailsReportingFilterRequestType = {
	imei?: string;
};

export const getDeviceDetailsReportingFilter = async ({
	imei,
}: GetDeviceDetailsReportingFilterRequestType) => {
	const params = new URLSearchParams();
	if (imei) params.append("imei", imei);

	return await axiosInstance
		.get(`page/device/filter?${params.toString()}`, {})
		.then(({ data }) => data);
};
