import cx from "classnames";

import { StyledProfileCard } from "@components/ProfileCard/StyledProfileCard";

export type ProfileCardProps = {
	className?: string;
	initials: string;
	userName: string;
};

export const ProfileCard = ({ className, initials, userName }: ProfileCardProps) => (
	<StyledProfileCard className={cx(className, "unselectable")}>
		<div className="profile-card__avatar">{initials}</div>
		<div className="profile-card__label">{userName}</div>
	</StyledProfileCard>
);
