import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import { Box } from "@mui/material";

export const StyledDeviceMarker = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		width: 290px;
		margin-bottom: -16px;
		z-index: 10;
		pointer-events: none;

		&.isSelected.isActiveStatus .device__marker--container {
			border: 2px solid ${theme?.palette?.customColors.blue300};
		}

		&.isSelected.isActiveStatus .device__marker--triangle {
			border-top: 16px solid ${theme?.palette?.customColors.blue300};
		}

		&.isOpen.isMovingStatus .device__marker--container {
			padding: 16px;
		}

		&.isSelected.isMovingStatus .device__marker--container {
			border: 2px solid ${theme?.palette?.customColors.red200};
		}

		&.isSelected.isMovingStatus .device__marker--triangle {
			border-top: 16px solid ${theme?.palette?.customColors.red200};
		}

		&.isSelected.isInactiveStatus .device__marker--container {
			border: 2px solid ${theme?.palette?.customColors.grey600};
		}

		&.isSelected.isInactiveStatus .device__marker--triangle {
			border-top: 16px solid ${theme?.palette?.customColors.grey600};
		}

		&.isSelected.isActiveStatus .device__marker--triangle::before,
		&.isSelected.isInactiveStatus .device__marker--triangle::before,
		&.isSelected.isMovingStatus .device__marker--triangle::before {
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-top: 16px solid ${theme?.palette?.customColors.white};
			bottom: 3px;
			left: -12px;
		}

		.device__marker--container {
			position: relative;
			padding: 7px;
			border-radius: 24px;
			border: 1px solid ${theme?.palette?.customColors.grey600};
			background: ${theme?.palette?.customColors.white};
			pointer-events: auto;
			cursor: pointer;

			&::before {
				content: "";
				width: 1px;
				height: calc(100% - 64px);
				position: absolute;
				top: 48px;
				left: 32px;
				background: ${theme?.palette?.customColors.red300};
			}
		}

		.device__marker--indicator {
			height: 32px;
			margin-top: 6px;
		}

		.device__marker--triangle-container {
			width: 100%;
			height: 16px;
			position: relative;
		}

		.device__marker--triangle {
			border-left: 13px solid transparent;
			border-right: 13px solid transparent;
			border-top: 16px solid ${theme?.palette?.customColors.grey600};
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
			z-index: 2;
			pointer-events: auto;
			cursor: pointer;
		}

		.device__marker--triangle::before {
			content: "";
			position: absolute;
			border-left: 13px solid transparent;
			border-right: 13px solid transparent;
			border-top: 16px solid ${theme?.palette?.customColors.white};
			bottom: 2px;
			left: -13px;
			z-index: -1;
		}

		.device__marker--imei,
		.device__marker--model {
			color: ${theme?.palette?.customColors.black100};
		}

		.device__marker--imei {
			font-weight: ${theme?.customTypography.fontWeight.semiBold};
		}

		.device__marker--model {
			font-size: ${theme?.customTypography.fontSize.fs14};
			line-height: ${theme?.customTypography.lineHeight.lh19};
		}
	`}
`;
