import { useLocation } from "react-router-dom";

import {
	DayBoundaryEnum,
	getFormattedDateAndTimeToUTCWithTimezone,
} from "@helpers/getFormattedDateAndTime";
import { useQuery } from "@tanstack/react-query";

import { getLocationTrackingList } from "../api/getLocationTrackingList";

export const useLocationTrackingList = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const formattedDateFrom = searchParams.get("date-from")
		? getFormattedDateAndTimeToUTCWithTimezone(
				searchParams.get("date-from") || "",
				searchParams.get("time-from") || "",
				DayBoundaryEnum.DAY_START,
			)
		: undefined;

	const formattedDateTo = searchParams.get("date-to")
		? getFormattedDateAndTimeToUTCWithTimezone(
				searchParams.get("date-to") || "",
				searchParams.get("time-to") || "",
				DayBoundaryEnum.DAY_END,
			)
		: undefined;

	const { data, refetch, isLoading } = useQuery({
		queryKey: ["locationTrackingList", formattedDateFrom, formattedDateTo],
		queryFn: () =>
			getLocationTrackingList({
				imei: searchParams.get("imei") || undefined,
				device: searchParams.get("device") || undefined,
				datafrom: formattedDateFrom,
				datato: formattedDateTo,
			}),
		enabled: true,
	});

	return {
		isLoading,
		refetch,
		data: data?.data?.content || [],
	};
};
