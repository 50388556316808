import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledBadgeCounter = styled.div`
	${({ theme }: { theme?: Theme }) => css`
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-size: ${theme?.customTypography?.fontSize.fs14};
		font-weight: ${theme?.customTypography?.fontWeight.semiBold};
		line-height: ${theme?.customTypography?.lineHeight.lh21};
		color: ${theme?.palette?.customColors.white};
		background: ${theme?.palette?.customColors.blue300};
	`}
`;
