import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledStatusRenderer = styled.div`
	${({ theme }: { theme?: Theme }) => css`
		display: flex;
		align-items: center;
		gap: 8px;
	`}
`;
