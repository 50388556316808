import type { MouseEvent, ReactNode } from "react";
import { useMemo, useRef, useState } from "react";

import { Dropdown } from "@components/CustomSelect/Dropdown/Dropdown";
import { List } from "@components/CustomSelect/List/List";
import { InputLabel } from "@components/InputLabel/InputLabel";
import { Search } from "@components/Search/Search";
import { Toggle } from "@components/Toggle/Toggle";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import { Box } from "@mui/material";

type SelectProps = {
	className?: string;
	label?: string;
	labelClassName?: string;
	toggleClassName?: string;
	selectedItemsQty?: number;
	options: string[];
	noOptionsText?: string;
	renderItem: (
		className: string,
		option: string,
		isSelected: boolean,
		onClick: (option: string) => void,
	) => ReactNode;
	placeholder: string;
	searchPlaceholder?: string;
	isMultiple?: boolean;
	value: string[];
	onChange: (value: string[]) => void;
	onClear?: () => void;
	isError?: boolean;
};

export const Select = ({
	className,
	label,
	labelClassName,
	toggleClassName,
	selectedItemsQty,
	placeholder,
	searchPlaceholder,
	options,
	renderItem,
	noOptionsText,
	isMultiple,
	value,
	onChange,
	onClear,
	isError,
}: SelectProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const popperRef = useRef<HTMLDivElement | null>(null);
	const toggleRef = useRef<HTMLButtonElement | null>(null);
	const [searchValue, setSearchValue] = useState<string>("");
	const isOpen = Boolean(anchorEl);
	const toggleLabel = value.length > 0 && !isMultiple ? `${value[0]}` : undefined;

	const filteredOptions = useMemo(
		() => options.filter((option) => option.toLowerCase().includes(searchValue.toLowerCase())),
		[options, searchValue],
	);

	const onToggle = (e: MouseEvent<HTMLButtonElement>) => {
		const target = !anchorEl ? e.currentTarget : null;
		setAnchorEl(target);
	};

	const onClose = () => {
		setAnchorEl(null);
	};

	const onOptionSelect = (option: string) => {
		if (isMultiple) {
			const isOptionExists = value.some((item) => item === option);
			if (isOptionExists) {
				onChange(value.filter((item) => item !== option));
			} else {
				onChange([...value, option]);
			}
		} else {
			onChange([option]);
			onClose();
		}
	};

	const onSearchChange = (value: string) => {
		setSearchValue(value);
	};

	useOnClickOutside(popperRef, onClose, true, [toggleRef]);

	return (
		<div className={className}>
			{label && <InputLabel className={labelClassName}>{label}</InputLabel>}
			<div ref={ref}>
				<Toggle
					className={toggleClassName}
					innerRef={toggleRef}
					isOpen={isOpen}
					label={toggleLabel}
					placeholder={placeholder}
					isError={isError}
					selectedItemsQty={selectedItemsQty}
					onToggle={onToggle}
					onClear={onClear}
				/>
				<Dropdown isOpen={isOpen} innerRef={popperRef} anchorElRef={toggleRef}>
					<Box mb={2}>
						<Search placeholder={searchPlaceholder} value={searchValue} onChange={onSearchChange} />
					</Box>
					<List
						isMultiple={isMultiple}
						selected={value}
						options={filteredOptions}
						renderItem={renderItem}
						noOptionsText={noOptionsText ?? "No options"}
						onSelect={onOptionSelect}
					/>
				</Dropdown>
			</div>
		</div>
	);
};
