import type { ICellRendererParams } from "ag-grid-community";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { StyledDateSpan, StyledTimeCellRenderer, StyledTimeSpan } from "./StyledTimeCellRenderer";
dayjs.extend(utc);

export const TimeCellRenderer = (props: ICellRendererParams) => {
	const formattedDate = props.value ? dayjs.utc(props.value).format("hh:mm:ss A|DD/MM/YYYY") : null;

	const [timePart, ...datePart] = formattedDate ? formattedDate.split("|") : [];
	const date = datePart.join(" ");

	return (
		<StyledTimeCellRenderer>
			{!props.value && "-"}
			{props.value && (
				<>
					<StyledTimeSpan>{timePart} </StyledTimeSpan>
					<StyledDateSpan>{date}</StyledDateSpan>
				</>
			)}
		</StyledTimeCellRenderer>
	);
};
