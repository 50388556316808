import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledTable = styled.div`
	${({ theme }: { theme?: Theme }) => css`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		width: 100%;
		--ag-background-color: unset;
		background-color: ${theme?.palette?.customColors.grey100};
		.ag-theme-material,
		.ag-theme-quartz,
		.ag-theme-balham,
		.ag-theme-alpine {
			height: 100%;
			--ag-icon-font-display-none: none;
			--ag-background-color: unset;
			--ag-border-color: none;
		}

		.ag-sort-indicator-icon {
			width: 16px;
			height: 16px;
		}

		.ag-center-cols-container {
			overflow: hidden;
		}

		.ag-center-cols-viewport {
			min-height: unset !important;
		}

		.ag-details-row {
			padding: 0;
		}

		.ag-root-wrapper .ag-root-wrapper {
			padding: 0px;
			border-radius: 0px 0px 8px 8px;
			border: 2px solid ${theme?.palette?.customColors.blue300};
			border-top: 0;
			transform: translateY(-8px);
		}

		.ag-header {
			background-color: ${theme?.palette?.customColors.white};
			margin-bottom: 8px;
			border-radius: 8px;
			font-size: 14px;
			font-weight: 600;
		}

		.ag-header-cell {
			padding-left: 24px;
		}

		.ag-row-level-1 .ag-header {
			background-color: #f7f8fa;
			margin-bottom: 0px;
			border-radius: 0;
			font-size: 14px;
			font-weight: 600;
			border-top: 1px solid ${theme?.palette?.customColors.grey200};
		}

		.ag-row:not(.ag-row-level-1) {
			background-color: ${theme?.palette?.customColors.white};
			border-radius: 16px;
			max-height: 62px;
		}

		.ag-row :not(.ag-row-level-1) .ag-cell {
			padding: 4px 0px;
		}

		.ag-cell {
			padding: 10px 24px;
			font-size: 16px;
		}

		.ag-row:not(.ag-row-level-0) .ag-cell {
			padding-left: 24px;
		}

		.ag-row-level-1 .ag-row {
			background-color: #f7f8fa;
			border-radius: 0;
			border-top: 1px solid ${theme?.palette?.customColors.grey200};
		}

		.ag-row-level-1 {
			overflow: visible;
		}

		.ag-row-expanded {
			border-radius: 8px 8px 0px 0px !important;
			border: 2px solid ${theme?.palette?.customColors.blue300};
			border-bottom: 0;
			box-sizing: border-box;
		}

		.ag-paging-panel {
			display: none;
		}
	`}
`;
