import type { SVGProps } from "react";

const CheckedBoxIcon = ({ width = "24", height = "24", ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM9.5
			17L4.5 12L6 10.5L9.5 14L17.5 6L19 7.5L9.5 17Z"
			fill="currentColor"
		/>
	</svg>
);
export default CheckedBoxIcon;
