import { axiosInstance } from "@services/axios";

type GetDeviceDetailsReportingListRequestType = {
	imei?: string;
	device?: string;
	memorycapacity?: string;
	osvesion?: string;
	status?: string;
	datafrom?: string;
	datato?: string;
	sortField?: string;
	sortOrder?: string;
	page: string;
	size: string;
};

export const getDeviceDetailsReportingList = async ({
	imei,
	device,
	memorycapacity,
	osvesion,
	status,
	datafrom,
	datato,
	sortField,
	sortOrder,
	page,
	size,
}: GetDeviceDetailsReportingListRequestType) => {
	const params = new URLSearchParams();

	if (imei) params.append("imei", imei);
	if (device) params.append("device", device);
	if (memorycapacity) params.append("memorycapacity", memorycapacity);
	if (osvesion) params.append("osvesion", osvesion);
	if (status) params.append("status", status);
	if (datafrom) params.append("datafrom", datafrom);
	if (datato) params.append("datato", datato);
	if (sortField) params.append("sortField", sortField);
	if (sortOrder) params.append("sortOrder", sortOrder);
	if (page) params.append("page", page);
	if (size) params.append("size", size);

	return await axiosInstance
		.get(`page/device/details?${params.toString()}`, {})
		.then(({ data }) => data);
};
