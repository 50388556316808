import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export interface PaginationButtonProps
	extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	active?: boolean;
	theme?: Theme;
}

export const StyledPaginationButton = styled.button<PaginationButtonProps>`
	${({ theme, active, disabled }) => css`
		background: ${active ? "white" : "transparent"};
		border: 0;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		width: 32px;
		cursor: pointer;
		color: black;
		opacity: ${disabled && 0.3};
		pointer-events: ${disabled ? "none" : "auto"};

		&:hover {
			background: ${theme?.palette?.customColors.white};
		}
	`};
`;
