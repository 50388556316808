import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import Box from "@mui/material/Box";

export const StyledSidebar = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		.sidebar__container {
			display: flex;
			flex-direction: column;
		}

		.sidebar__nav {
			display: flex;
			flex: 1;
		}

		.menu-list .sidebar__toggle {
			padding: 4px;
			font-size: ${theme?.customTypography?.fontSize?.fs14};
			line-height: ${theme?.customTypography?.lineHeight?.lh21};
		}

		.menu-list .sidebar__toggle .menu-list-item__cta {
			align-items: center;
		}

		.menu-list .sidebar__toggle .menu-list-item__cta--icon {
			width: 24px;
			height: 24px;
		}

		.menu-list .sidebar__toggle .menu-list-item__cta--label {
			padding-top: 0;
		}

		&.isCollapsed .menu-list .sidebar__toggle,
		&.isCollapsed .menu-list .menu-list-item__cta {
			display: flex;
			justify-content: center;
		}

		&.isCollapsed .menu-list .menu-list-item__cta--icon {
			margin: 0;
		}

		&.isCollapsed .menu-list .menu-list-item__cta--label {
			display: none;
		}

		&.isCollapsed .menu-list .sidebar__toggle {
			transform: rotate(180deg);
		}

		&.isCollapsed .sidebar__profile {
			display: flex;
			justify-content: center;
		}

		&.isCollapsed .sidebar__profile .profile-card__label {
			display: none;
		}

		.sidebar__logout .menu-list-item__cta--label {
			letter-spacing: ${theme?.customTypography?.letterSpacing.ls03};
		}
	`}
`;
