import cx from "classnames";

import { ArrowIcon } from "@icons/index";
import { Box } from "@mui/material";
import { StyledToggle } from "@pages/LocationTracking/Devices/Toggle/StyledToggle";

type ToggleProps = {
	className?: string;
	appendLabel: string | number;
	label: string;
	isOpen: boolean;
	onClick: () => void;
};

export const Toggle = ({ className, appendLabel, label, isOpen, onClick }: ToggleProps) => (
	<StyledToggle className={cx(className, { isOpen })} onClick={onClick}>
		<div className="toggle__label">
			<Box component="span" mr={1}>
				{appendLabel}
			</Box>
			<span>{label}</span>
		</div>
		<Box className="toggle__icon-wrapper" component="span" ml={1}>
			<ArrowIcon className="toggle__icon" />
		</Box>
	</StyledToggle>
);
