import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import { Box } from "@mui/material";

export const StyledDeviceInfoRow = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		position: relative;

		.device-info__indicator {
			position: absolute;
			top: -5px;
			left: -40px;
		}

		.device-info__label {
			font-size: ${theme?.customTypography.fontSize.fs14};
			font-weight: ${theme?.customTypography.fontWeight.semiBold};
			color: ${theme?.palette?.customColors.black100};
			line-height: ${theme?.customTypography.lineHeight.lh21};
		}

		.device-info__label,
		.device-info__row-content {
			color: ${theme?.palette?.customColors.black100};
		}

		.device-info__row-label {
			font-size: ${theme?.customTypography.fontSize.fs12};
			color: ${theme?.palette?.customColors.grey600};
			line-height: ${theme?.customTypography.lineHeight.lh16};
		}

		.device-info__row-content {
			font-size: ${theme?.customTypography.fontSize.fs14};
			line-height: ${theme?.customTypography.lineHeight.lh19};
		}
	`}
`;
