import { forwardRef } from "react";

import type { StyledContainerProps } from "./StyledContainer";
import { StyledContainer } from "./StyledContainer";

export const Container = forwardRef<HTMLDivElement, StyledContainerProps>(
	({ children, visible, ...props }, ref) => (
		<StyledContainer ref={ref} visible={visible} {...props}>
			{children}
		</StyledContainer>
	),
);
