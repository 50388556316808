import type { ReactNode } from "react";

import type { ButtonProps } from "@mui/material";

import { StyledButton } from "./StyledButton";

interface CustomButtonProps extends ButtonProps {
	children: ReactNode;
	active?: boolean | "";
}

export const Button = ({ children, active, ...props }: CustomButtonProps) => (
	<StyledButton active={active} {...props}>
		{children}
	</StyledButton>
);
