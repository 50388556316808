import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import { LicenseManager } from "ag-grid-enterprise";

import { ToasterCloseButton } from "@components/ToasterMessage/ToasterCloseButton/ToasterCloseButton";
import { Global } from "@emotion/react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { CssBaseline } from "@mui/material";
import { AppRoutes } from "@routes/AppRoutes";
import { setupInterceptors } from "@services/axios";
import { useUser } from "@store/userContext";
import { globalStyles } from "@theme/index";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

export const App = () => {
	const { userResetOnTokenExpiration } = useUser();

	useEffect(() => {
		setupInterceptors(userResetOnTokenExpiration);
	}, [userResetOnTokenExpiration]);

	return (
		<>
			<CssBaseline />
			<Global styles={globalStyles} />
			<AppRoutes />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				closeOnClick={true}
				pauseOnHover={true}
				draggable={true}
				icon={<CheckOutlinedIcon />}
				closeButton={<ToasterCloseButton />}
			/>
		</>
	);
};
