import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import Select from "@mui/material/Select";

export const StyledSelect = styled(Select)`
	${({ theme }: { theme?: Theme }) => css`
		color: black;
		height: 32px;
		border: ${theme?.palette?.customColors.grey200};
		padding-left: 8px;
		padding-right: 8px;

		& .MuiOutlinedInput-notchedOutline {
			border: none;
		}
	`}
`;
