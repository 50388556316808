import type { SVGProps } from "react";

const FilterIcon = ({ width = "24", height = "24", ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			d="M4 3H20.0001C20.5524 3 21.0001 3.44764 21.0001 3.99987L21.0003 5.58569C21.0004 5.85097 20.895 6.10538 20.7074 6.29295L14.2929 12.7071C14.1054 12.8946 14 13.149 14 13.4142V19.7192C14 20.3698 13.3886 20.8472 12.7575 20.6894L10.7575 20.1894C10.3123 20.0781 10 19.6781 10 19.2192V13.4142C10 13.149 9.89464 12.8946 9.70711 12.7071L3.29289 6.29289C3.10535 6.10536 3 5.851 3 5.58579V4C3 3.44772 3.44771 3 4 3Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default FilterIcon;
