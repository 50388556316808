import { axiosInstance } from "@services/axios";

type GetLocationTrackingCSVRequestType = {
	imei?: string;
	device?: string;
	datafrom?: string;
	datato?: string;
};

export const getLocationTrackingCSV = async ({
	imei,
	device,
	datafrom,
	datato,
}: GetLocationTrackingCSVRequestType) => {
	const params = new URLSearchParams();
	if (imei) params.append("imei", imei);
	if (device) params.append("device", device);
	if (datafrom) params.append("datefrom", datafrom);
	if (datato) params.append("dateto", datato);

	return await axiosInstance
		.get(`page/location/csv?${params.toString()}`, {})
		.then(({ data }) => data);
};
