import type { ReactNode } from "react";

import cx from "classnames";

import { StyledPanelHeader } from "@layout/DashboardLayout/PanelHeader/StyledPanelHeader";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

type PanelHeaderProps = {
	className?: string;
	wrapperClassName?: string;
	rightAppendClassName?: string;
	appendBottomClassName?: string;
	title: string;
	appendRight?: ReactNode;
	appendBottom?: ReactNode;
};

export const PanelHeader = ({
	wrapperClassName,
	className,
	rightAppendClassName,
	appendBottomClassName,
	title,
	appendRight,
	appendBottom,
}: PanelHeaderProps) => (
	<StyledPanelHeader className={cx(wrapperClassName, "panel-header")}>
		<Box className={cx(className, "panel-header__top")} mb={3}>
			<Box className="panel-header__top--title">
				{title && <Typography variant="h1">{title}</Typography>}
			</Box>
			{appendRight && (
				<Box className={cx(rightAppendClassName, "panel-header__top--right")} pl={6}>
					{appendRight}
				</Box>
			)}
		</Box>
		{appendBottom && (
			<Box className={cx(appendBottomClassName, "panel-header__bottom--extra-content")} mb={2}>
				{appendBottom}
			</Box>
		)}
	</StyledPanelHeader>
);
