import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from "react-router-dom";

import { GuestRoute } from "@hoc/GuestRoute/GuestRoute";
import { RequireAuth } from "@hoc/RequireAuth/RequireAuth";
import { DataUsageMonitoring } from "@pages/DataUsageMonitoring/DataUsageMonitoring";
import { DeviceDetailsReporting } from "@pages/DeviceDetailsReporting/DeviceDetailsReporting";
import { LocationTracking } from "@pages/LocationTracking/LocationTracking";
import { Login } from "@pages/Login/Login";
import { PageNotFound } from "@pages/PageNotFound/PageNotFound";
import { UserActivityTracking } from "@pages/UserActivityTracking/UserActivityTracking";

export const AppRoutes = () => {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route>
				<Route element={<GuestRoute />}>
					<Route path="/" element={<Login />} />
				</Route>
				<Route element={<RequireAuth />}>
					<Route path="/user-activity-tracking" element={<UserActivityTracking />} />
					<Route path="/device-details-reporting" element={<DeviceDetailsReporting />} />
					<Route path="/data-usage-monitoring" element={<DataUsageMonitoring />} />
					<Route path="/location-tracking" element={<LocationTracking />} />
				</Route>
				<Route path="*" element={<PageNotFound />} />
			</Route>,
		),
	);

	return <RouterProvider router={router} />;
};
