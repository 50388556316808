import type { SVGProps } from "react";

const TimerIcon = ({ width = "16", height = "16", ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<g clipPath="url(#clip0_2387_18431)">
			<path
				d="M8 4V8H12"
				stroke="#101218"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.5909 6.99967C14.1084 3.79217 11.3407 1.33301 7.9987 1.33301C4.3168 1.33301 1.33203 4.31777 1.33203 7.99967C1.33203 11.6815 4.3168 14.6663 7.9987 14.6663C10.7324 14.6663 13.0819 13.0209 14.1106 10.6663"
				stroke="#101218"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.332 10.667H14.2654C14.4863 10.667 14.6654 10.8461 14.6654 11.067V14.0003"
				stroke="#101218"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2387_18431">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default TimerIcon;
