import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

export const StyledDatePicker = styled(DateRangePicker)`
	${({ theme }: { theme?: Theme }) => css`
		.react-daterange-picker__wrapper {
			display: none;
		}
		.react-daterange-picker__calendar {
			width: 260px;
			position: static !important;
		}
		.react-calendar {
			width: 260px;
			max-width: 260px;
			gap: 16px;
			border: none;
			width: 100%;
			position: static !important;
		}
		.react-calendar__navigation {
			display: flex;
			justify-content: center;
			height: unset;
		}
		.react-calendar__navigation__label {
			min-width: unset;
			max-width: 84px;
			padding: 0;
			height: 30px;
		}
		.react-calendar__navigation button {
			min-width: unset;
		}
		.react-calendar__navigation__arrow {
			display: flex;
			width: 32px;
			max-width: 32px;
			padding: 0;
			height: 32px;
			align-items: center;
			justify-content: center;
		}
		.react-calendar__navigation__arrow:disabled {
			background-color: unset;
			opacity: 0.3;
		}
		.react-calendar__month-view__days {
			gap: 8px;
		}
		.react-calendar__month-view .react-calendar__tile {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 0;
			border-radius: 50%;
			height: 30px;
			width: 30px;
			max-width: 30px;
		}

		.react-calendar__tile--now:not(.react-calendar__tile--hasActive):not(
				.react-calendar__tile--rangeEnd
			):not(.react-calendar__tile--rangeStart) {
			background: ${theme?.palette?.customColors.white};
		}

		.react-calendar__tile--now:hover:not(.react-calendar__tile--rangeEnd):not(
				.react-calendar__tile--hasActive
			) {
			background: ${theme?.palette?.customColors.grey100};
		}
		.react-calendar__tile--hasActive {
			color: ${theme?.palette?.customColors.white};
			background-color: ${theme?.palette?.customColors.blue300} !important;
		}
		.react-calendar__month-view__weekdays {
			font-size: 14px;
			padding: 0;
			text-transform: unset;
			font-weight: unset;
			gap: 8px;
			margin-bottom: 8px;
		}
		abbr[title] {
			text-decoration: none;
		}
		.react-calendar__month-view__weekdays__weekday {
			padding: 0;
			max-width: 30px;
		}
		.react-calendar__month-view
			.react-calendar__tile.react-calendar__tile--active:not(.react-calendar__tile--rangeStart):not(
				.react-calendar__tile--rangeEnd
			) {
			background-color: ${theme?.palette?.customColors.grey100};
			color: black;
		}
		.react-calendar__tile--active {
			background-color: ${theme?.palette?.customColors.blue300};
			position: relative;
		}
		.react-calendar__tile:disabled {
			background-color: unset;
		}
	`}
`;
