import type { ReactNode } from "react";

import Box from "@mui/material/Box";

type LabelProps = {
	className?: string;
	children: ReactNode;
};

export const Label = ({ className, children }: LabelProps) => (
	<Box className={className} display="flex" alignItems="center" component="span">
		{children}
	</Box>
);
