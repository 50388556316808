import type { SVGProps } from "react";

const AppleIcon = ({ width = "22", height = "22", ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			d="M13.1104 4.96387C12.5542 5.63623 11.6245 6.13428 10.8857 6.13428C10.7944 6.13428 10.7114 6.11768 10.6616 6.10938C10.6533 6.06787 10.6284 5.94336 10.6284 5.81055C10.6284 4.96387 11.0601 4.11719 11.5332 3.58594C12.1226 2.88867 13.1187 2.37402 13.9487 2.34082C13.9653 2.43213 13.9736 2.54834 13.9736 2.66455C13.9736 3.51123 13.6167 4.34961 13.1104 4.96387ZM13.6914 6.30859C14.1562 6.30859 15.8413 6.3584 16.9453 7.94385C16.8457 8.01855 15.1772 8.96484 15.1772 11.0732C15.1772 13.5054 17.3022 14.377 17.3687 14.3936C17.3604 14.4517 17.0283 15.5723 16.248 16.7261C15.5425 17.7305 14.8037 18.7515 13.6914 18.7515C12.5708 18.7515 12.2803 18.0957 11.002 18.0957C9.74023 18.0957 9.29199 18.7681 8.2793 18.7681C7.25 18.7681 6.53613 17.8301 5.72266 16.6846C4.76807 15.3315 4.00439 13.2314 4.00439 11.2393C4.00439 8.05176 6.07959 6.3584 8.12988 6.3584C9.20898 6.3584 10.1055 7.06396 10.7944 7.06396C11.4419 7.06396 12.4546 6.30859 13.6914 6.30859Z"
			fill="#101218"
		/>
	</svg>
);

export default AppleIcon;
