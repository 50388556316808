import type { SVGProps } from "react";

const DataUsageIcon = ({ width = "24", height = "24", ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			d="M4 6V12C4 12 4 15 11 15C18 15 18 12 18 12V6"
			stroke="#444C66"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11 3C18 3 18 6 18 6C18 6 18 9 11 9C4 9 4 6 4 6C4 6 4 3 11 3Z"
			stroke="#444C66"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11 21C4 21 4 18 4 18V12"
			stroke="#444C66"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M15 21V19" stroke="#444C66" strokeWidth="1.5" strokeLinecap="round" />
		<path d="M18 21V17" stroke="#444C66" strokeWidth="1.5" strokeLinecap="round" />
		<path d="M21 21V15" stroke="#444C66" strokeWidth="1.5" strokeLinecap="round" />
	</svg>
);

export default DataUsageIcon;
