import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import { Popper } from "@mui/material";

export const StyledDropdown = styled(Popper)`
	${({ theme }: { theme?: Theme }) => css`
		width: 280px !important;
		border: 1px solid ${theme?.palette?.customColors.grey200};
		border-radius: 16px;
		padding: 16px;
		background-color: ${theme?.palette?.customColors.white};
		z-index: 3;
	`}
`;
