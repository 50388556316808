import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import { FormControl } from "@mui/material";

export const StyledInput = styled(FormControl)`
	${({ theme }: { theme?: Theme }) => css`
		.MuiInputBase-root {
			border-radius: 16px;
		}

		.MuiInputBase-input {
			height: 48px;
			padding: 8px 16px;
			box-sizing: border-box;
			color: ${theme?.palette?.customColors.black200};
			letter-spacing: ${theme?.customTypography?.letterSpacing.ls03};
			z-index: 1;
		}

		fieldset {
			padding: 0;
			border-color: ${theme?.palette?.customColors.grey200};
		}

		.MuiInputBase-root:hover fieldset {
			border-width: 1px;
			border-color: ${theme?.palette?.customColors.grey300};
			background: ${theme?.palette?.customColors.blue600};
		}

		.MuiInputBase-root.Mui-error fieldset,
		.MuiInputBase-root.Mui-focused fieldset {
			border-width: 2px;
		}

		.MuiInputBase-root.Mui-focused fieldset {
			border-color: ${theme?.palette?.customColors.blue300};
			background: ${theme?.palette?.customColors.white};
		}

		.MuiInputBase-root.Mui-error:hover fieldset,
		.MuiInputBase-root.Mui-error.Mui-focused fieldset {
			border-color: ${theme?.palette?.error.main};
		}

		.MuiInputBase-root .MuiInputBase-input::placeholder,
		.MuiInputBase-root.Mui-error .MuiInputBase-input::placeholder {
			color: ${theme?.palette?.customColors.grey600};
		}

		.MuiFormHelperText-root.Mui-error {
			margin: 4px 0 0;
			position: absolute;
			left: 0;
			top: 100%;
		}
	`}
`;
