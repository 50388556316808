import styled from "@emotion/styled";

export const StyledActiveTimeRenderer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	svg {
		flex-shrink: 0;
	}
`;
