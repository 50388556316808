import { StyledInput } from "@components/Input/StyledInput";
import { InputLabel } from "@components/InputLabel/InputLabel";
import type { TextFieldProps } from "@mui/material";
import { TextField } from "@mui/material";

export type InputProps = {} & TextFieldProps;

export enum InputFieldEnum {
	TEXT = "text",
	NUMBER = "number",
	PASSWORD = "password",
	EMAIL = "email",
	HIDDEN = "hidden",
}

export const Input = ({ id, label, ...rest }: InputProps) => (
	<StyledInput fullWidth>
		{label && <InputLabel htmlFor={id}>{label}</InputLabel>}
		<TextField id={id} variant="outlined" {...rest} />
	</StyledInput>
);
