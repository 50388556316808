import cx from "classnames";

import { CheckedBoxIcon, UncheckedBoxIcon } from "@icons/index";
import { Box } from "@mui/material";

import { StyledCustomSelectOption } from "./StyledCustomSelectOption";

export const CustomSelectOption = (
	className: string,
	option: string,
	isSelected: boolean,
	onClick: (option: string) => void,
) => {
	const selectIcon = isSelected ? (
		<CheckedBoxIcon className="icon isChecked" />
	) : (
		<UncheckedBoxIcon className="icon" />
	);

	return (
		<StyledCustomSelectOption
			className={cx(className, { isSelected })}
			component="li"
			onClick={() => onClick(option)}
		>
			{selectIcon}
			<Box component="span" pl={1}>
				{option}
			</Box>
		</StyledCustomSelectOption>
	);
};
