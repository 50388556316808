import cx from "classnames";

import { StyledMenuList } from "@components/MenuList/StyledMenuList";
import type { BoxProps } from "@mui/material";

type MenuListProps = {
	wrapperClassName?: string;
} & BoxProps;

export const MenuList = ({ wrapperClassName, className, children, ...rest }: MenuListProps) => (
	<StyledMenuList className={wrapperClassName} {...rest}>
		<ul className={cx("menu-list", className)}>{children}</ul>
	</StyledMenuList>
);
