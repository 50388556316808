import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

export enum DayBoundaryEnum {
	DAY_START = "DAY_START",
	DAY_END = "DAY_END",
}

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const getFormattedDateToUTCWithTimezone = (
	date: string,
	boundary: DayBoundaryEnum = DayBoundaryEnum.DAY_START,
): string => {
	const formattedDate = dayjs.tz(date, "DD.MM.YYYY", "UTC");

	if (boundary === DayBoundaryEnum.DAY_START) {
		return formattedDate.startOf("day").utc().format("YYYY-MM-DD HH:mm:ss[+00]");
	} else if (boundary === DayBoundaryEnum.DAY_END) {
		return formattedDate.endOf("day").utc().format("YYYY-MM-DD HH:mm:ss[+00]");
	}

	return formattedDate.utc().format("YYYY-MM-DD HH:mm:ss[+00]");
};
