import { LogoIcon, LogoSmartIcon } from "@icons/index";

export enum LogoVariantEnum {
	DEFAULT = "default",
	LARGE = "large",
	SMART = "smart",
}

type LogoProps = {
	variant?: LogoVariantEnum;
};
export const Logo = ({ variant = LogoVariantEnum.DEFAULT }: LogoProps) => {
	switch (true) {
		case variant === LogoVariantEnum.LARGE:
			return <LogoIcon width="164" height="51" />;
		case variant === LogoVariantEnum.SMART:
			return <LogoSmartIcon />;
		default:
			return <LogoIcon />;
	}
};
