import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import FormControl from "@mui/material/FormControl";

export const StyledSelectPageSize = styled(FormControl)`
	${({ theme }: { theme?: Theme }) => css`
		margin: 4px;
		border-radius: 8px;
		color: black;
		background-color: ${theme?.palette?.customColors.white};
	`}
`;
