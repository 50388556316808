import dayjs from "dayjs";

export const downloadCSV = (data: string, fileName: string) => {
	const dateTime = dayjs().format("YYYY-MM-DD_HH-mm-ss");

	const formattedFileName = `${fileName}_${dateTime}.csv`;

	const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
	const link = document.createElement("a");
	const url = window.URL.createObjectURL(blob);

	if (link.download !== undefined) {
		link.href = url;
		link.download = formattedFileName;
		link.style.visibility = "hidden";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	} else {
		window.open(url);
	}
};
