import type { BoxProps } from "@mui/material";
import { Box, Typography } from "@mui/material";

type TitleProps = {
	title: string;
	innerClassName?: string;
	variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
} & BoxProps;

export const Title = ({ innerClassName, title, variant, ...rest }: TitleProps) => (
	<Box {...rest}>
		<Typography className={innerClassName} variant={variant}>
			{title}
		</Typography>
	</Box>
);
