import InputMask from "react-input-mask";

import { Input } from "@components/Input/Input";
import type { TextFieldProps } from "@mui/material";
import { Box } from "@mui/material";

interface MaskedValueChangeState {
	value: string;
	selection: {
		start: number;
		end: number;
	};
}

type TimeInputProps = TextFieldProps & {
	defaultValue?: string;
	value?: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const TimeInput = (props: TimeInputProps) => {
	const formatChars = {
		h: "[0-1]",
		H: "[0-9]",
		I: "[0-5]",
		i: "[0-9]",
		A: "[AaPp]",
		M: "[Mm]",
	};

	const beforeMaskedValueChange = (newState: MaskedValueChangeState) => {
		let { value } = newState;
		const timeParts = value.split(":");
		const hourPart = timeParts[0];

		if (hourPart?.startsWith("1")) {
			formatChars["H"] = "[0-2]";
		} else if (hourPart?.startsWith("0")) {
			formatChars["H"] = "[1-9]";
		} else {
			formatChars["H"] = "[0-9]";
		}

		if (parseInt(hourPart, 10) > 12) {
			const correctedHour = (parseInt(hourPart, 10) - 12).toString().padStart(2, "0");
			value = correctedHour + value.slice(2);
		}

		return { value, selection: newState.selection };
	};

	return (
		<InputMask
			mask="hH:Ii AM"
			placeholder="HH:MM AM"
			formatChars={formatChars}
			beforeMaskedValueChange={beforeMaskedValueChange}
			defaultValue={props.defaultValue ?? ""}
			onChange={props.onChange}
			value={props.value}
		>
			{/* @ts-ignore */}
			{(inputProps) => (
				<Box width="120px">
					<Input {...inputProps} {...props} />
				</Box>
			)}
		</InputMask>
	);
};
