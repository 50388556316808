import type { ReactNode } from "react";
import { useEffect, useRef } from "react";

import cx from "classnames";

import { BadgeCounter } from "@components/BadgeCounter/BadgeCounter";
import { DownloadIcon, FilterBoldIcon, FilterIcon } from "@icons/index";
import { Box, Button, Typography } from "@mui/material";
import { StyledHeaderPanel } from "@pages/LocationTracking/PanelHeader/StyledHeaderPanel";

type PanelHeaderProps = {
	setTopOffset: (val: number) => void;
	isFilterOpen: boolean;
	onFilterToggle: () => void;
	isSidebarOpen: boolean;
	filters: ReactNode;
	selectedFilterCount?: number;
	isCSVExportPending?: boolean;
	onExportToCSV?: () => void;
};

export const PanelHeader = ({
	setTopOffset,
	isFilterOpen,
	onFilterToggle,
	isSidebarOpen,
	filters,
	selectedFilterCount = 0,
	isCSVExportPending,
	onExportToCSV,
}: PanelHeaderProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const isFiltersSelected = selectedFilterCount > 0;
	const isActive = isFiltersSelected || isFilterOpen;
	const filterIcon = isActive ? <FilterBoldIcon /> : <FilterIcon />;

	useEffect(() => {
		if (ref.current) {
			setTopOffset(ref?.current.scrollHeight + 40);
		}
	}, [isFilterOpen, setTopOffset]);

	return (
		<StyledHeaderPanel ref={ref} className={cx({ isCollapsed: !isSidebarOpen })} px={3} py={2.5}>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<div>
					<Typography variant="h1">Location tracking</Typography>
				</div>
				<Box display="flex" pl={6} gap={3}>
					<Button
						disableRipple
						className={cx({ isActive })}
						startIcon={filterIcon}
						onClick={onFilterToggle}
					>
						<Box mr={isFiltersSelected ? 1 : 0} component="span">
							Filter
						</Box>
						{isFiltersSelected && <BadgeCounter count={selectedFilterCount} />}
					</Button>
					<Button
						variant="text"
						disableRipple
						startIcon={<DownloadIcon />}
						onClick={onExportToCSV}
						disabled={isCSVExportPending}
					>
						Download .CSV
					</Button>
				</Box>
			</Box>
			{isFilterOpen && <Box mt={3}>{filters}</Box>}
		</StyledHeaderPanel>
	);
};
