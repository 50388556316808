import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledToggle = styled.button`
	${({ theme }: { theme?: Theme }) => css`
		width: 100%;
		height: 48px;
		padding: 8px 16px;
		border: 1px solid ${theme?.palette?.customColors.grey200};
		border-radius: 16px;
		font-size: ${theme?.customTypography?.fontSize.fs16};
		color: ${theme?.palette?.customColors.grey600};
		line-height: ${theme?.customTypography?.lineHeight.lh22};
		letter-spacing: ${theme?.customTypography?.letterSpacing.ls03};
		background-color: ${theme?.palette?.customColors.white};
		cursor: pointer;

		&.isOpen {
			padding: 6px 15px;
			border: 2px solid ${theme?.palette?.customColors.blue300} !important;
		}

		&.isSelected {
			color: ${theme?.palette?.customColors.white};
		}

		&:hover {
			background-color: ${theme?.palette?.customColors.blue600};
			border-color: ${theme?.palette?.customColors.grey300};
		}

		.select__toggle--inner {
			display: flex;
			align-items: center;
			justify-content: start;
		}

		.select__toggle--value {
			flex: 1;
			display: flex;
			align-items: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
			line-height: 1;
		}

		.select__toggle--ctas {
			flex-shrink: 0;
			display: flex;
			align-items: center;
		}

		&.isOpen .select__toggle--dropdown-indicator {
			transform: rotate(180deg);
		}

		.select__toggle--dropdown-selected-qty,
		.select__toggle--dropdown-indicator {
			display: flex;
			align-items: center;
		}

		.select__toggle--dropdown-selected-qty {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			justify-content: center;
			font-weight: ${theme?.customTypography?.fontWeight.bold};
			color: ${theme?.palette?.customColors.white};
			line-height: 1;
			background-color: ${theme?.palette?.customColors.blue300};
		}

		.select__toggle--dropdown-selected-date {
			height: 32px;
			width: 100%;
			max-width: 176px;
			border-radius: 8px;
			align-items: center;
			padding: 8px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			display: block;
			font-weight: ${theme?.customTypography?.fontWeight.bold};
			font-size: ${theme?.customTypography?.fontSize.fs14};
			color: ${theme?.palette?.customColors.white};
			line-height: 1;
			background-color: ${theme?.palette?.customColors.blue300};
		}
	`}
`;
