import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { ButtonProps, Theme } from "@mui/material";
import { Button } from "@mui/material";

interface StyledButtonProps extends ButtonProps {
	theme?: Theme;
	active?: boolean | "";
}

export const StyledButton = styled(Button)<StyledButtonProps>`
	${({ theme, active }) => css`
		width: 141px;
		justify-content: flex-start;
		text-align: left;
		padding: 8px 16px;
		font-size: 16px;
		line-height: 22.4px;
		font-weight: ${active ? 700 : 400} !important;
		border-radius: 80px;
		color: ${active ? theme?.palette?.customColors.blue200 : "black"} !important;
		background-color: ${active ? theme?.palette?.customColors.blue600 : "transparent"};

		&:hover {
			background-color: ${theme?.palette?.action.hover};
		}
	`}
`;
