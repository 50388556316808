import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import { Box } from "@mui/material";

export const StyledDevices = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		width: 283px;
		position: fixed;
		top: 152px;
		margin-left: 40px;
		border-radius: 16px;
		border: 1px solid ${theme?.palette?.customColors.grey200};
		background-color: ${theme?.palette?.customColors.white};
		z-index: 1;

		&.isSidebarCollapsed {
			left: 128px;
		}

		&.isFilterOpen {
			top: 224px;
		}

		.MuiTypography-h1 {
			color: ${theme?.palette?.customColors.black100};
			font-weight: ${theme?.customTypography?.fontWeight.regular};
			font-size: ${theme?.customTypography?.fontSize.fs34};
			line-height: ${theme?.customTypography?.lineHeight.lh47};
			letter-spacing: ${theme?.customTypography?.letterSpacing.ls06};
		}

		.simplebar-track.simplebar-vertical {
			right: 4px !important;
		}
	`}
`;
