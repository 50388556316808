import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import type { DatePickerValueType } from "@components/DatePicker/DatePicker";
import type { FilterType } from "@components/Filters/Filters";
import { FilterEnum } from "@constants";
import { DayBoundaryEnum, getFormattedDateToUTCWithTimezone } from "@helpers/getFormattedDate";
import { getQueryParamsCount } from "@helpers/getQueryParamsCount";
import { useQuery } from "@tanstack/react-query";

import { getDataUsageMonitoringFilter } from "../api/getDataUsageMonitoringFilter";

export const useDataUsageMonitoringFilter = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(0);
	const [selectedIMEI, setSelectedIMEI] = useState<string[]>(
		searchParams.get("imei")?.split(",") || [],
	);
	const [selectedDevices, setSelectedDevices] = useState<string[]>(
		searchParams.get("device")?.split(",") || [],
	);
	const [selectedApplications, setSelectedApplications] = useState<string[]>(
		searchParams.get("application")?.split(",") || [],
	);
	const [selectedTimeRange, setSelectedTimeRange] = useState<DatePickerValueType>(
		searchParams.get("date-from") && searchParams.get("date-to")
			? {
					dateFrom: searchParams.get("date-from")!,
					dateTo: searchParams.get("date-to")!,
					timeFrom: searchParams.get("time-from")!,
					timeTo: searchParams.get("time-to")!,
				}
			: null,
	);

	const queryString = useMemo(() => {
		const params = new URLSearchParams(location.search);

		if (selectedIMEI.length > 0) {
			params.set("imei", selectedIMEI.join(","));
		} else {
			params.delete("imei");
		}

		if (selectedDevices.length > 0) {
			params.set("device", selectedDevices.join(","));
		} else {
			params.delete("device");
		}

		if (selectedApplications.length > 0) {
			params.set("application", selectedApplications.join(","));
		} else {
			params.delete("application");
		}

		if (selectedTimeRange) {
			params.set("date-from", selectedTimeRange.dateFrom);
			params.set("date-to", selectedTimeRange.dateTo);
			if (selectedTimeRange.timeFrom && selectedTimeRange.timeTo) {
				params.set("time-from", selectedTimeRange.timeFrom);
				params.set("time-to", selectedTimeRange.timeTo);
			}
		} else {
			params.delete("date-from");
			params.delete("date-to");
			params.delete("time-from");
			params.delete("time-to");
		}

		return decodeURIComponent(params.toString());
	}, [selectedIMEI, selectedDevices, selectedApplications, selectedTimeRange, location.search]);

	const onIMEIClear = () => setSelectedIMEI([]);
	const onApplicationsClear = () => setSelectedApplications([]);
	const onDevicesClear = () => setSelectedDevices([]);

	const onClearAllFilters = () => {
		setSelectedIMEI([]);
		setSelectedDevices([]);
		setSelectedApplications([]);
		setSelectedTimeRange(null);
	};

	const { data, isLoading, refetch } = useQuery({
		queryKey: ["dataUsageMonitoringFilter"],
		queryFn: () => {
			const formattedDateFrom = selectedTimeRange?.dateFrom
				? getFormattedDateToUTCWithTimezone(selectedTimeRange?.dateFrom, DayBoundaryEnum.DAY_START)
				: undefined;
			const formattedDateTo = selectedTimeRange?.dateTo
				? getFormattedDateToUTCWithTimezone(selectedTimeRange?.dateTo, DayBoundaryEnum.DAY_END)
				: undefined;
			return getDataUsageMonitoringFilter({
				imei: selectedIMEI.join(","),
				device: selectedDevices.join(","),
				datafrom: formattedDateFrom,
				datato: formattedDateTo,
			});
		},
		enabled: true,
	});

	useEffect(() => {
		navigate(`${location.pathname}?${queryString}`, { replace: true });
		refetch();
		setSelectedFiltersCount(getQueryParamsCount(["imei", "application", "device", "date-from"]));
	}, [queryString, navigate, location.pathname, refetch]);

	const filters: (FilterType<string[]> | FilterType<DatePickerValueType>)[] = [
		{
			filterId: "imei",
			placeholder: "Select IMEI",
			searchPlaceholder: "Enter text",
			isMultiple: true,
			options: data?.data.imei || [],
			value: selectedIMEI,
			onChange: setSelectedIMEI,
			onClear: onIMEIClear,
			type: FilterEnum.DROPDOWN,
		},
		{
			filterId: "application",
			placeholder: "Select Application",
			isMultiple: true,
			options: data?.data.applications || [],
			value: selectedApplications,
			onChange: setSelectedApplications,
			onClear: onApplicationsClear,
			type: FilterEnum.DROPDOWN,
		},
		{
			filterId: "device",
			placeholder: "Select Device",
			isMultiple: true,
			options: data?.data.device_names || [],
			value: selectedDevices,
			onChange: setSelectedDevices,
			onClear: onDevicesClear,
			type: FilterEnum.DROPDOWN,
		},
		{
			filterId: "date",
			placeholder: "Select Date",
			isMultiple: false,
			value: selectedTimeRange,
			onChange: setSelectedTimeRange,
			type: FilterEnum.DATA_PICKER,
		},
	];

	return {
		isLoading,
		filters,
		onClearAllFilters,
		selectedFiltersCount,
	};
};
