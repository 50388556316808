import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import Box from "@mui/material/Box";

export const StyledCustomSelectOption = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		padding: 9px 0;
		display: flex;
		cursor: pointer;

		& > span {
			margin-top: 1px;
			letter-spacing: ${theme?.customTypography?.letterSpacing.ls03};
		}

		.icon {
			flex-shrink: 0;
		}

		.isChecked {
			color: ${theme?.palette?.customColors.blue300};
		}
	`}
`;
