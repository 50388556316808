import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { DefaultHeaderCTA } from "@components/DefaultHeaderCTA/DefaultHeaderCTA";
import { Filters } from "@components/Filters/Filters";
import { Table } from "@components/Table/Table";
import { ToasterMessage } from "@components/ToasterMessage/ToasterMessage";
import { TABLE_FOR_PAGE } from "@constants";
import { useDeviceDetailsReportingCSVExport } from "@hooks/useDeviceDetailsReportingCSVExport";
import { useDeviceDetailsReportingFilter } from "@hooks/useDeviceDetailsReportingFilter";
import { useDeviceDetailsReportingList } from "@hooks/useDeviceDetailsReportingList";
import { DashboardLayout } from "@layout/DashboardLayout/DashboardLayout";

const PAGE_TITLE = "Device details reporting";

export const DeviceDetailsReporting = () => {
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const { isPending, isError, onCSVDownload } = useDeviceDetailsReportingCSVExport();
	const { filters, selectedFiltersCount, onClearAllFilters } = useDeviceDetailsReportingFilter();
	const { data, page, pageSize, onPageChange, onPageSizeChange, sortParams, osSortChange } =
		useDeviceDetailsReportingList();

	const { gridOptions, detailGridOptions } = TABLE_FOR_PAGE.DEVICE_DETAILS_REPORTING;

	const onFilterToggle = () => setIsFilterOpen((prev) => !prev);

	useEffect(() => {
		if (isError) {
			toast.error(<ToasterMessage title="Something went wrong" message="" />);
		}
	}, [isError]);

	return (
		<DashboardLayout
			headerTitle={PAGE_TITLE}
			headerAppendRight={
				<DefaultHeaderCTA
					isFilterOpen={isFilterOpen}
					onFilterToggle={onFilterToggle}
					isCSVExportPending={isPending}
					onExportToCSV={onCSVDownload}
					selectedFilterCount={selectedFiltersCount}
				/>
			}
			headerAppendBottom={
				<Filters isOpen={isFilterOpen} filters={filters} onClearAll={onClearAllFilters} />
			}
		>
			<Table
				gridOptions={gridOptions}
				detailGridOptions={detailGridOptions}
				rowData={data?.content ?? []}
				totalRowCount={data?.totalElements ?? 0}
				page={page}
				setPage={onPageChange}
				pageSize={pageSize}
				setPageSize={onPageSizeChange}
				sortParams={sortParams}
				setSortParams={osSortChange}
			/>
		</DashboardLayout>
	);
};
