import type { MouseEvent } from "react";

import cx from "classnames";

import { DeviceStatusEnum } from "@constants";
import {
	InactiveStatusIcon,
	KeyboardArrowDownIcon,
	KeyboardArrowUpIcon,
	OfflineStatusIcon,
	OnlineStatusIcon,
} from "@icons/index";
import { Box, Typography } from "@mui/material";
import { DeviceInfoRow } from "@pages/LocationTracking/LocationTrackingMap/DeviceMarker/DeviceInfoRow/DeviceInfoRow";
import { StyledDeviceMarker } from "@pages/LocationTracking/LocationTrackingMap/DeviceMarker/StyledDeviceMarker";
import type { LocationTrackingDeviceType } from "@type/LocationTrackingTypes";
import { AdvancedMarker } from "@vis.gl/react-google-maps";

type DeviceMarkerType = {
	openDevice: string;
	selectedDevice: LocationTrackingDeviceType | null;
	onSelectMapDevice: (val: LocationTrackingDeviceType) => void;
	onSetMarkerRef: (
		marker: google.maps.marker.AdvancedMarkerElement | null,
		key: string,
		isOpen: boolean,
		openDeviceIMEI: string,
	) => void;
	setMarkers: (val: { [key: string]: google.maps.marker.AdvancedMarkerElement }) => void;
} & LocationTrackingDeviceType;

export const DeviceMarker = ({
	openDevice,
	uuid,
	imei,
	address,
	device_name,
	lng,
	lat,
	status,
	updated_at,
	prevLocations,
	selectedDevice,
	onSelectMapDevice,
	onSetMarkerRef,
	setMarkers,
}: DeviceMarkerType) => {
	const isOpen = openDevice === imei && imei.length > 0;
	const isPreviousLocationsExists = prevLocations && prevLocations.length > 0;
	const isMovingStatus = status === DeviceStatusEnum.MOVING;
	const isInactiveStatus = status === DeviceStatusEnum.INACTIVE;
	const isActiveStatus = status === DeviceStatusEnum.ACTIVE;
	const isDeviceSelected = imei === selectedDevice?.imei;
	const currentDevice = {
		uuid,
		imei,
		device_name,
		address,
		updated_at,
		status,
		lng,
		lat,
		prevLocations,
	};

	const isMarkerVisible =
		!selectedDevice || openDevice === "" || (selectedDevice && selectedDevice.imei === imei);

	const onSelectDevice = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setMarkers({});
		onSelectMapDevice(currentDevice);
	};

	return (
		<>
			{isMarkerVisible ? (
				<AdvancedMarker
					position={{ lat: lat, lng: lng }}
					title={device_name}
					ref={(marker) =>
						onSetMarkerRef(
							marker as google.maps.marker.AdvancedMarkerElement,
							imei,
							isOpen,
							openDevice,
						)
					}
					zIndex={isDeviceSelected ? 1000 : undefined}
				>
					<StyledDeviceMarker
						display="flex"
						flexDirection="column"
						className={cx("unselectable", {
							isMovingStatus,
							isInactiveStatus,
							isActiveStatus,
							isSelected: isDeviceSelected,
							isOpen,
						})}
					>
						<Box className="device__marker--container" onClick={onSelectDevice}>
							<Box
								className={cx({
									"cursor-pointer": isMovingStatus,
									"cursor-default": !isMovingStatus,
								})}
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								<Box display="flex" className="device__marker--content">
									<Box className="device__marker--indicator" display="inline-flex" flexShrink={0}>
										{isMovingStatus && <OfflineStatusIcon />}
										{isInactiveStatus && <InactiveStatusIcon />}
										{isActiveStatus && <OnlineStatusIcon />}
									</Box>
									<Box pl={1}>
										<Typography className="device__marker--imei">{imei || "—"}</Typography>
										<Typography className="device__marker--model">{device_name}</Typography>
									</Box>
								</Box>
								{isMovingStatus && isPreviousLocationsExists && (
									<Box display="inline-flex" flexShrink={0} className="device__toggle">
										{isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
									</Box>
								)}
							</Box>
							{isOpen && (
								<Box
									pl={5}
									maxHeight={"50vh"}
									overflow={"auto"}
									onWheel={(e) => {
										e.stopPropagation();
									}}
								>
									<DeviceInfoRow address={address} lastUpdate={updated_at} />
									{prevLocations?.map((location, index) => (
										<DeviceInfoRow
											key={index}
											label={index === 0 ? "Last location" : "Previous location"}
											address={location.address}
											lastUpdate={location.updated_at}
										/>
									))}
								</Box>
							)}
						</Box>
						<div className="device__marker--triangle-container">
							<div className="device__marker--triangle" onClick={onSelectDevice} />
						</div>
						{isOpen && isMovingStatus && isPreviousLocationsExists && (
							<Box display="flex" justifyContent="center">
								<OfflineStatusIcon />
							</Box>
						)}
					</StyledDeviceMarker>
				</AdvancedMarker>
			) : (
				<></>
			)}
		</>
	);
};
