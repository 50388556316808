import { StyledFormHeader } from "@components/FormHeader/StyledFormHeader";
import type { BoxProps } from "@mui/material";
import { Box, Typography } from "@mui/material";

type FormHeaderProps = {
	title: string;
	content?: string;
} & BoxProps;

export const FormHeader = ({ title, content, ...rest }: FormHeaderProps) => (
	<StyledFormHeader {...rest}>
		<Typography className="form-header__title" variant="h3">
			{title}
		</Typography>
		{content && (
			<Box mt={3}>
				<Typography className="form-header__content">{content}</Typography>
			</Box>
		)}
	</StyledFormHeader>
);
