import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

import { EndIcon, NextIcon, PrevIcon, StartIcon } from "@icons/index";

import { PaginationButton } from "./PaginationButton/PaginationButton";
import { SelectPageSize } from "./SelectPageSize/SelectPageSize";
import { StyledSelectPageSizeContainer } from "./SelectPageSizeContainer/StyledSelectPageSizeContainer";
import { StyledPagination } from "./StyledPagination";

type PaginationProps = {
	pageSize: string;
	setPageSize: (value: string) => void;
	page: string;
	setPage: Dispatch<SetStateAction<string>>;
	totalRowCount: string | undefined;
};

export const Pagination = ({
	page,
	setPage,
	pageSize,
	setPageSize,
	totalRowCount,
}: PaginationProps) => {
	const [totalPages, setTotalPages] = useState(0);

	useEffect(() => {
		if (totalRowCount && pageSize) {
			const totalPages = Math.ceil(Number(totalRowCount) / Number(pageSize));
			setTotalPages(totalPages);
		}
	}, [totalRowCount, pageSize]);

	const goToPage = (page: number) => {
		if (page >= 0 && page < totalPages) {
			setPage(String(page + 1));
		}
	};

	const renderPageNumbers = () => {
		const currentPage = Number(page);
		const maxVisiblePages = 9;
		const pages = [];

		if (totalPages <= maxVisiblePages) {
			for (let i = 1; i <= totalPages; i++) {
				pages.push(
					<PaginationButton key={i} onClick={() => goToPage(i - 1)} active={i === currentPage}>
						{i}
					</PaginationButton>,
				);
			}
		} else {
			const startPages = [1, 2, 3];
			const endPages = [totalPages - 2, totalPages - 1, totalPages];
			const middlePages = [currentPage - 1, currentPage, currentPage + 1].filter(
				(p) => p > 3 && p < totalPages - 2,
			);

			const hasLeftEllipsis = currentPage > 5;
			const hasRightEllipsis = currentPage < totalPages - 4;

			startPages.forEach((p) => {
				pages.push(
					<PaginationButton key={p} onClick={() => goToPage(p - 1)} active={p === currentPage}>
						{p}
					</PaginationButton>,
				);
			});

			if (hasLeftEllipsis) {
				pages.push(<span key="left-ellipsis">...</span>);
			}

			middlePages.forEach((p) => {
				pages.push(
					<PaginationButton key={p} onClick={() => goToPage(p - 1)} active={p === currentPage}>
						{p}
					</PaginationButton>,
				);
			});

			if (hasRightEllipsis) {
				pages.push(<span key="right-ellipsis">...</span>);
			}

			endPages.forEach((p) => {
				pages.push(
					<PaginationButton key={p} onClick={() => goToPage(p - 1)} active={p === currentPage}>
						{p}
					</PaginationButton>,
				);
			});
		}

		return pages;
	};

	return (
		<StyledPagination>
			<PaginationButton onClick={() => goToPage(0)} disabled={Number(page) === 1}>
				<StartIcon />
			</PaginationButton>
			<PaginationButton onClick={() => goToPage(Number(page) - 2)} disabled={Number(page) === 1}>
				<PrevIcon />
			</PaginationButton>
			{renderPageNumbers()}
			<PaginationButton
				onClick={() => goToPage(Number(page))}
				disabled={Number(page) === totalPages}
			>
				<NextIcon />
			</PaginationButton>
			<PaginationButton
				onClick={() => goToPage(totalPages - 1)}
				disabled={Number(page) === totalPages}
			>
				<EndIcon />
			</PaginationButton>
			<StyledSelectPageSizeContainer>
				<SelectPageSize pageSize={pageSize} setPageSize={setPageSize} />
				of {totalRowCount} entries
			</StyledSelectPageSizeContainer>
		</StyledPagination>
	);
};
