import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledList = styled.div`
	${({ theme }: { theme?: Theme }) => css`
		max-height: 210px;
		display: flex;
		overflow: hidden;

		& > div {
			width: 100%;
		}

		.select__dropdown--list {
			padding: 0 31px 0 0;
		}

		& > div > div {
			height: 100%;
		}

		.select__dropdown--no-options {
			font-size: ${theme?.customTypography?.fontSize.fs16};
			font-weight: ${theme?.customTypography?.fontWeight.semiBold};
			letter-spacing: ${theme?.customTypography?.letterSpacing.ls03};
		}
	`}
`;
