import type { SVGProps } from "react";

const EndIcon = ({ width = "20", height = "20", ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			d="M15 5.83301V14.1663"
			stroke="black"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.80982 4.38974C5.48193 4.13088 5 4.36443 5 4.78218V15.2187C5 15.6364 5.48193 15.8699 5.80982 15.6111L12.4196 10.3928C12.6732 10.1927 12.6732 9.80816 12.4196 9.60799L5.80982 4.38974Z"
			stroke="black"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default EndIcon;
