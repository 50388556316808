/** @jsxImportSource @emotion/react */
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

import type {
	FirstDataRenderedEvent,
	GridApi,
	GridOptions,
	GridReadyEvent,
	RowClassParams,
	RowGroupOpenedEvent,
	SortChangedEvent,
} from "ag-grid-community";
import type { RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import type { CallRecordType, RowDataType, SortParamsType } from "@type/TableTypes";

import { Pagination } from "./Pagination/Pagination";
import { StyledTable } from "./StyledTable";

export type TableProps = {
	gridOptions: GridOptions;
	detailGridOptions?: GridOptions;
	rowData: RowDataType[];
	page: string;
	setPage: Dispatch<SetStateAction<string>>;
	pageSize: string;
	setPageSize: Dispatch<SetStateAction<string>>;
	totalRowCount: string;
	sortParams: SortParamsType;
	setSortParams: Dispatch<SetStateAction<SortParamsType>>;
};

export const Table = ({
	gridOptions,
	detailGridOptions,
	rowData,
	totalRowCount,
	page,
	setPage,
	pageSize,
	setPageSize,
	sortParams,
	setSortParams,
}: TableProps) => {
	const [gridApi, setGridApi] = useState<GridApi | null>(null);

	const onGridReady = (params: GridReadyEvent) => {
		setGridApi(params.api);
	};

	const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
		// some logic after render
	};

	const getRowClass = (params: RowClassParams<RowDataType>) => {
		if (detailGridOptions && params.node.expanded) {
			return "ag-row-expanded";
		} else return "";
	};

	const onRowGroupOpened = (params: RowGroupOpenedEvent) => {
		if (detailGridOptions) {
			params.api.redrawRows(); // handicap rows rerender to create handicap blue border around two rows
		}
	};

	const onRowClicked = (params: RowClickedEvent<RowDataType>) => {
		if (detailGridOptions) {
			const isExpanded = params.node.expanded;
			params.node.setExpanded(!isExpanded);
		}
	};

	const getDetailRowData = (params: {
		data: RowDataType;
		successCallback: (data: CallRecordType[]) => void;
	}) => {
		const callRecords = params.data.applications ?? [];
		params.successCallback(callRecords);
	};

	const onSortChange = (params: SortChangedEvent) => {
		if (params.columns && params.columns.length > 0) {
			const isNextCol = params?.columns && params?.columns.length === 2 ? true : false;
			const sortField = isNextCol ? params?.columns[1]?.getColId() : params?.columns[0]?.getColId();
			const sortOrder = isNextCol ? params?.columns[1]?.getSort() : params?.columns[0]?.getSort();

			if (sortOrder && sortField) {
				setSortParams([sortField, sortOrder]);
			} else {
				setSortParams(null);
			}
		}
	};

	useEffect(() => {
		if (gridApi && sortParams) {
			const [sortField, sortOrder] = sortParams;

			if (sortField && sortOrder) {
				gridApi.applyColumnState({
					state: [{ colId: sortField, sort: sortOrder }],
				});
			} else {
				gridApi.applyColumnState({
					state: [],
				});
			}
		}
	}, [sortParams, gridApi]);

	return (
		<StyledTable>
			<div className="ag-theme-quartz">
				<AgGridReact
					gridOptions={gridOptions}
					detailCellRendererParams={{ detailGridOptions, getDetailRowData }}
					onGridReady={onGridReady}
					rowData={rowData}
					onFirstDataRendered={onFirstDataRendered}
					getRowClass={getRowClass}
					onRowClicked={onRowClicked}
					onRowGroupOpened={onRowGroupOpened}
					paginationPageSize={Number(pageSize)}
					onSortChanged={onSortChange}
				/>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				pageSize={pageSize}
				setPageSize={setPageSize}
				totalRowCount={totalRowCount}
			/>
		</StyledTable>
	);
};
