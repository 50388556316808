import { css } from "@emotion/react";
import createTheme from "@mui/material/styles/createTheme";
import { cssVars } from "@theme/variables";

export const globalStyles = css`
	html {
		font-size: 16px;
	}

	a {
		text-decoration: none;
	}

	.unselectable {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.cursor-pointer {
		cursor: pointer;
	}
`;

export const theme = createTheme({
	palette: {
		primary: {
			main: cssVars.color.blue300,
			light: cssVars.color.blue400,
			dark: cssVars.color.blue200,
		},
		customColors: cssVars.color,
		error: {
			main: cssVars.color.red100,
			dark: cssVars.color.red200,
			light: cssVars.color.red300,
		},
		text: {
			primary: cssVars.color.white,
		},
	},
	typography: {
		fontFamily: cssVars.fontFamily.redHatDisplay,
		allVariants: {
			color: cssVars.color.blue100,
		},
		h1: {
			fontSize: cssVars.fontSize.fs96,
			lineHeight: cssVars.lineHeight.lh134,
			letterSpacing: cssVars.letterSpacing.ls12,
		},
		h2: {
			fontSize: cssVars.fontSize.fs60,
			lineHeight: cssVars.lineHeight.lh84,
			letterSpacing: cssVars.letterSpacing.ls12,
		},
		h3: {
			fontSize: cssVars.fontSize.fs48,
			lineHeight: cssVars.lineHeight.lh67,
			letterSpacing: cssVars.letterSpacing.ls1,
		},
		h4: {
			fontSize: cssVars.fontSize.fs34,
			lineHeight: cssVars.lineHeight.lh47,
			letterSpacing: cssVars.letterSpacing.ls06,
		},
		h5: {
			fontSize: cssVars.fontSize.fs24,
			lineHeight: cssVars.lineHeight.lh33,
			letterSpacing: cssVars.letterSpacing.ls06,
		},
		h6: {
			fontSize: cssVars.fontSize.fs20,
			lineHeight: cssVars.lineHeight.lh28,
			letterSpacing: cssVars.letterSpacing.ls06,
		},
		subtitle1: {
			fontSize: cssVars.fontSize.fs16,
			lineHeight: cssVars.lineHeight.lh22,
			letterSpacing: cssVars.letterSpacing.ls03,
		},
		subtitle2: {
			fontSize: cssVars.fontSize.fs14,
			lineHeight: cssVars.lineHeight.lh21,
		},
		body1: {
			fontSize: cssVars.fontSize.fs16,
			lineHeight: cssVars.lineHeight.lh22,
			letterSpacing: cssVars.letterSpacing.ls03,
		},
		body2: {
			fontSize: cssVars.fontSize.fs14,
			lineHeight: cssVars.lineHeight.lh19,
			letterSpacing: cssVars.letterSpacing.ls03,
		},
		overline: {
			fontSize: cssVars.fontSize.fs14,
			lineHeight: cssVars.lineHeight.lh19,
			letterSpacing: cssVars.letterSpacing.ls06,
		},
	},
	customTypography: {
		fontWeight: cssVars.fontWeight,
		lineHeight: cssVars.lineHeight,
		letterSpacing: cssVars.letterSpacing,
		fontSize: cssVars.fontSize,
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
				.unselectable {
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				.Toastify__toast-container {
					width: 400px;
					padding: 0;
				}
				.Toastify__toast-container--top-right {
					top: 40px;
					right: 40px;
				}
				.Toastify__toast-icon {
					width: auto;
					margin: 0 16px 0 0;
				}
				.Toastify__toast-body {
					align-items: start;
				}
				.Toastify__toast {
					color: ${cssVars.color.white};
				}
				
				.Toastify__toast--error {
					background-color: ${cssVars.color.red100};
				}
				
				.Toastify__toast--success {
					background-color: ${cssVars.color.green100};
				}
		    `,
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					padding: "6px 16px",
					borderRadius: "16px",
					fontSize: cssVars.fontSize.fs16,
					lineHeight: cssVars.lineHeight.lh22,
					letterSpacing: cssVars.letterSpacing.ls03,
					"&.MuiAlert-standardError": {
						color: cssVars.color.red200,
						backgroundColor: cssVars.color.red300,
					},
					"&.MuiAlert-standardError .MuiAlert-icon": {
						color: cssVars.color.red200,
					},
				},
				message: {
					fontWeight: cssVars.fontWeight.semiBold,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					minWidth: "140px",
					borderRadius: "16px",
					padding: "15px 22px 14px",
					fontSize: cssVars.fontSize.fs16,
					fontWeight: cssVars.fontWeight.semiBold,
					lineHeight: cssVars.lineHeight.lh22,
					letterSpacing: cssVars.letterSpacing.ls03,
					textTransform: "capitalize",
					color: cssVars.color.white,
					"&.Mui-disabled": {
						color: cssVars.color.grey600,
						backgroundColor: cssVars.color.grey300,
					},
				},
				containedPrimary: {
					backgroundColor: cssVars.color.blue300,
					"&:hover": {
						backgroundColor: cssVars.color.blue200,
					},
				},
				text: {
					padding: 0,
					minWidth: "auto",
					borderRadius: 0,
					textTransform: "none",
					"&.Mui-disabled": {
						color: `${cssVars.color.grey300} !important`,
						backgroundColor: "transparent !important",
					},
					"&.Mui-disabled .MuiButton-icon": {
						color: `${cssVars.color.grey300} !important`,
					},
					"&:hover": {
						backgroundColor: "transparent!important",
					},
					"&[href]": {
						color: cssVars.color.blue300,
						"&:hover": {
							color: cssVars.color.blue200,
						},
					},
					"&:not([href])": {
						fontWeight: cssVars.fontWeight.semiBold,
						color: cssVars.color.blue100,
						lineHeight: cssVars.lineHeight.lh22,
						letterSpacing: cssVars.letterSpacing.ls03,
						"& .MuiButton-icon": {
							color: cssVars.color.black200,
						},
						"&:hover, &:hover .MuiButton-icon, &.isActive, &.isActive .MuiButton-icon": {
							color: cssVars.color.blue300,
						},
					},
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: "16px",
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					fontSize: cssVars.fontSize.fs14,
					lineHeight: cssVars.lineHeight.lh19,
					letterSpacing: cssVars.letterSpacing.ls03,
				},
			},
		},
	},
});
