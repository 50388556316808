import type { Dispatch, ReactNode, SetStateAction } from "react";
import { useState } from "react";

import cx from "classnames";

import { Sidebar } from "@components/Sidebar/Sidebar";
import { PanelHeader } from "@layout/DashboardLayout/PanelHeader/PanelHeader";
import { StyledDashboardLayout } from "@layout/DashboardLayout/StyledDashboardLayout";
import { Box } from "@mui/material";

type DashboardLayoutProps = {
	headerTitle?: string;
	isHeaderHidden?: boolean;
	headerWrapperClassName?: string;
	headerClassName?: string;
	headerAppendRightClassName?: string;
	headerAppendBottomClassName?: string;
	headerAppendRight?: ReactNode;
	headerAppendBottom?: ReactNode;
	containerOffset?: number;
	children: ReactNode;
	isSidebarOpen?: boolean;
	setIsSidebarOpen?: Dispatch<SetStateAction<boolean>>;
};

export const DashboardLayout = ({
	headerTitle,
	isHeaderHidden,
	headerWrapperClassName,
	headerClassName,
	headerAppendRightClassName,
	headerAppendBottomClassName,
	headerAppendRight,
	headerAppendBottom,
	containerOffset,
	children,
	isSidebarOpen: externalSidebarOpen,
	setIsSidebarOpen: externalSetIsSidebarOpen,
}: DashboardLayoutProps) => {
	const [internalIsSidebarOpen, setInternalIsSidebarOpen] = useState<boolean>(true);

	const isSidebarOpen =
		externalSidebarOpen !== undefined ? externalSidebarOpen : internalIsSidebarOpen;
	const setIsSidebarOpen = externalSetIsSidebarOpen ?? setInternalIsSidebarOpen;

	const onSidebarToggle = () => setIsSidebarOpen((prev) => !prev);

	return (
		<StyledDashboardLayout className={cx({ isCollapsed: !isSidebarOpen })}>
			<Sidebar
				className="dashboard__sidebar"
				isSidebarOpen={isSidebarOpen}
				onSidebarToggle={onSidebarToggle}
			/>
			<Box className="dashboard__container" p={containerOffset ?? 5} pb={0}>
				{!isHeaderHidden && (
					<PanelHeader
						title={headerTitle ?? ""}
						wrapperClassName={cx("dashboard__container--header", headerWrapperClassName)}
						className={headerClassName}
						rightAppendClassName={headerAppendRightClassName}
						appendRight={headerAppendRight}
						// extraContentClassName={headerAppendBottomClassName}
						appendBottom={headerAppendBottom}
					/>
				)}
				<Box className="dashboard__container--body">{children}</Box>
			</Box>
		</StyledDashboardLayout>
	);
};
