import type { RefObject } from "react";

import { ScrollBar } from "@components/ScrollBar/ScrollBar";
import { Box } from "@mui/material";
import { DevicesList } from "@pages/LocationTracking/Devices/DevicesList/DevicesList";
import type { LocationTrackingDeviceType } from "@type/LocationTrackingTypes";

type DropdownProps = {
	topOffset: number;
	devices: LocationTrackingDeviceType[];
	selectedDevice: LocationTrackingDeviceType | null;
	onDeviceChange: (val: LocationTrackingDeviceType) => void;
	devicesScrollBarRef: RefObject<HTMLDivElement | null>;
};

const TOGGLE_HEIGHT = 76;
const TOP_DEVICES_OFFSET = 22;
const INNER_DROPDOWN_TOP_OFFSET = 24;
const BOTTOM_MAP_OFFSET = 40;

export const Dropdown = ({
	topOffset,
	devicesScrollBarRef,
	devices,
	selectedDevice,
	onDeviceChange,
}: DropdownProps) => {
	const dropdownHeight = `calc(100vh - ${topOffset + TOP_DEVICES_OFFSET + TOGGLE_HEIGHT + INNER_DROPDOWN_TOP_OFFSET + BOTTOM_MAP_OFFSET}px)`;

	return (
		<Box mt={3} sx={{ height: dropdownHeight, overflow: "hidden" }}>
			{/* @ts-ignore */}
			<ScrollBar ref={devicesScrollBarRef} style={{ height: dropdownHeight }}>
				<DevicesList
					devices={devices}
					selectedDevice={selectedDevice}
					onDeviceChange={onDeviceChange}
				/>
			</ScrollBar>
		</Box>
	);
};
