import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import { Box } from "@mui/material";

export const StyledDeviceItem = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		border-radius: 24px;
		border: 2px solid ${theme?.palette?.customColors.grey100};
		background-color: ${theme?.palette?.customColors.grey100};
		cursor: pointer;

		&.isSelected:not(.isMovingOrInactive) {
			border-color: ${theme?.palette?.customColors.blue300};
		}

		&.isSelected.isMovingStatus {
			border-color: ${theme?.palette?.customColors.red200};
		}

		&.isSelected.isInactiveStatus {
			border-color: ${theme?.palette?.customColors.grey600};
		}

		&:not(:first-of-type) {
			margin-top: 24px;
		}

		.device {
			border-bottom: 1px solid ${theme?.palette?.customColors.grey300};
		}

		&.isActiveStatus .device__status--label {
			color: ${theme?.palette?.customColors.blue300};
		}

		&.isMovingStatus .device__status--label {
			color: ${theme?.palette?.customColors.red200};
		}

		&.isInactiveStatus .device__status--label {
			color: ${theme?.palette?.customColors.grey600};
		}

		.device__status--label {
			line-height: ${theme?.customTypography?.lineHeight.lh21};
		}

		&.isSelected.isDeviceMovingStatus .device__status--label {
			color: ${theme?.palette?.customColors.red200};
		}

		&.isSelected.isInactiveStatus .device__status--label {
			color: ${theme?.palette?.customColors.grey600};
		}

		.device__status--label,
		.device__imei {
			font-weight: ${theme?.customTypography?.fontWeight.semiBold};
		}

		.device__imei,
		.device__name,
		.device__info--content {
			color: ${theme?.palette?.customColors.black100};
		}

		.device__info--label,
		.device__imei,
		.device__name,
		.device__info--content {
			letter-spacing: ${theme?.customTypography?.letterSpacing.ls03};
		}

		.device__status--label,
		.device__name,
		.device__info--label {
			font-size: ${theme?.customTypography?.fontSize.fs14};
		}

		.device__name,
		.device__info--label {
			line-height: ${theme?.customTypography?.lineHeight.lh19};
		}

		.device__info--label {
			color: ${theme?.palette?.customColors.grey600};
		}
	`}
`;
