import type { ReactNode } from "react";

import Box from "@mui/material/Box";

type IconProps = {
	className?: string;
	icon: ReactNode;
};

export const Icon = ({ className, icon }: IconProps) => (
	<Box className={className} display="flex" alignItems="center" component="span">
		{icon}
	</Box>
);
