import type { ReactNode, RefObject } from "react";

import { StyledDropdown } from "./StyledDropDown";

type DropDownProps = {
	isOpen: boolean;
	children: ReactNode;
	innerRef: RefObject<HTMLDivElement>;
	anchorElRef: RefObject<HTMLButtonElement>;
};

export const Dropdown = ({ isOpen, innerRef, anchorElRef, children }: DropDownProps) => (
	<StyledDropdown
		open={isOpen}
		ref={innerRef}
		anchorEl={anchorElRef.current}
		placement="bottom-start"
		style={{ width: anchorElRef.current ? anchorElRef.current.clientWidth : undefined }}
		modifiers={[
			{
				name: "offset",
				options: {
					offset: [0, 4],
				},
			},
		]}
		transition={false}
	>
		{children}
	</StyledDropdown>
);
