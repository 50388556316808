import type { RefObject } from "react";
import { useEffect } from "react";

export const useOnClickOutside = (
	ref: RefObject<HTMLElement>,
	handler: (e: Event) => void,
	options?: boolean | AddEventListenerOptions,
	excludeRefs: RefObject<HTMLElement>[] = [],
) => {
	useEffect(() => {
		const listener = (event: Event) => {
			const isInsideExcluded = excludeRefs.some(
				(ref) => ref?.current && ref.current.contains(event.target as Node),
			);
			if (isInsideExcluded) {
				return;
			}

			!ref.current?.contains(event.target as Node) && handler(event);
		};
		document.addEventListener("click", listener, options);
		return () => {
			document.removeEventListener("click", listener, options);
		};
	}, [ref, handler, options, excludeRefs]);
};
