import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import type { BoxProps } from "@mui/material";
import { Box } from "@mui/material";

export interface StyledContainerProps extends BoxProps {
	theme?: Theme;
	visible?: boolean;
}

export const StyledContainer = styled(Box)<StyledContainerProps>`
	${({ theme, visible }) => css`
		display: ${visible ? "flex" : "none"};
		gap: 16px;
		position: absolute;
		margin-top: 8px;
		padding: 16px;
		background-color: white;
		border-radius: 16px;
		border: 1px solid ${theme?.palette?.customColors.grey200};
		box-shadow: 0 4px 32px rgba(0, 0, 0, 0.04);
		z-index: 100;

		.divider {
			background-color: ${theme?.palette?.customColors.grey200};
			width: 1px;
			height: auto;
		}

		.dash {
			width: 12px;
			height: 1px;
			background-color: #999999;
		}
	`}
`;
