import "react-toastify/dist/ReactToastify.css";
import "simplebar-react/dist/simplebar.min.css";

import { createRoot } from "react-dom/client";

import { Amplify } from "aws-amplify";

import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { UserProvider } from "@store/userContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { theme } from "@theme/index";

import awsConfig from "../amplifyconfiguration.json";
import { App } from "./App";

const container = document.getElementById("root");
if (!container) throw new Error("Root element with ID 'root' was not found in the document.");

const root = createRoot(container);

Amplify.configure(awsConfig);
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

root.render(
	<QueryClientProvider client={queryClient}>
		<ThemeProvider theme={theme}>
			<UserProvider>
				<App />
			</UserProvider>
		</ThemeProvider>
	</QueryClientProvider>,
);
