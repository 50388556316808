import type { RowHeightParams } from "ag-grid-community";

import { gridSizes } from "@constants";

export const getRowHeight = (params: RowHeightParams) => {
	const isDetailRow = params.node.detail;
	const rowThreshold = gridSizes.detailRowThreshold;
	const bordersSum = 2;
	const detailHeaderHeight = gridSizes.detailHeaderHeight;
	const rowHeightPerChild = gridSizes.detailRowHeight;
	const maxHeight = rowThreshold * rowHeightPerChild + detailHeaderHeight;

	if (!isDetailRow || !params.data.applications) return undefined;

	const childRowCount = params.data.applications.length;

	if (childRowCount <= rowThreshold) {
		return childRowCount * rowHeightPerChild + detailHeaderHeight + bordersSum;
	}

	return maxHeight - bordersSum;
};
