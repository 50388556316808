import type { ICellRendererParams } from "ag-grid-community";

import { StyledNumberCellRenderer } from "./StyledNumberCellRenderer";

export const NumberCellRenderer = (props: ICellRendererParams) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let value: any;

	if (typeof props.value === "number") {
		value = props.value;
	} else if (!isNaN(parseFloat(props.value))) {
		value = parseFloat(props.value);
	} else {
		value = props.value;
	}

	const formattedValue = String(value);

	return <StyledNumberCellRenderer>{formattedValue} MB</StyledNumberCellRenderer>;
};
