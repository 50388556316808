import { Link } from "react-router-dom";

import cx from "classnames";

import { Logo, LogoVariantEnum } from "@components/Logo/Logo";
import { MenuList } from "@components/MenuList/MenuList";
import { MenuListItem } from "@components/MenuListItem/MenuListItem";
import { ProfileCard } from "@components/ProfileCard/ProfileCard";
import { StyledSidebar } from "@components/Sidebar/StyledSidebar";
import { getShortInitials } from "@helpers/getInitials";
import {
	ActivityBoldIcon,
	ActivityIcon,
	DataUsageBoldIcon,
	DataUsageIcon,
	DeviceDetailsBoldIcon,
	DeviceDetailsIcon,
	DoubleArrowsLeftIcon,
	DoubleArrowsRightIcon,
	ExitIcon,
	LocationBoldIcon,
	LocationIcon,
} from "@icons/index";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { useUser } from "@store/userContext";

type SidebarProps = {
	className?: string;
	isSidebarOpen: boolean;
	onSidebarToggle: () => void;
};

export const Sidebar = ({ className, isSidebarOpen, onSidebarToggle }: SidebarProps) => {
	const { onLogout, user } = useUser();
	const logoVariant = isSidebarOpen ? LogoVariantEnum.DEFAULT : LogoVariantEnum.SMART;
	const firstname = user?.firstName ?? "";
	const lastname = user?.lastName ?? "";
	const shortInitials = getShortInitials(firstname, lastname);
	const userName = firstname && lastname ? `${firstname} ${lastname}` : "None";

	return (
		<StyledSidebar
			className={cx(className, { isCollapsed: !isSidebarOpen })}
			px={2}
			py={3}
			display="flex"
			flexDirection="column"
		>
			<Box display="flex" justifyContent="center">
				<Link to="/">
					<Logo variant={logoVariant} />
				</Link>
			</Box>
			<Box py={7.5} className="sidebar__nav">
				<MenuList>
					<MenuListItem
						icon={<ActivityIcon />}
						selectedIcon={<ActivityBoldIcon />}
						href="/user-activity-tracking"
					>
						User activity tracking
					</MenuListItem>
					<MenuListItem
						icon={<DeviceDetailsIcon />}
						selectedIcon={<DeviceDetailsBoldIcon />}
						href="/device-details-reporting"
					>
						Device details reporting
					</MenuListItem>
					<MenuListItem
						icon={<DataUsageIcon />}
						selectedIcon={<DataUsageBoldIcon />}
						href="/data-usage-monitoring"
					>
						Data usage monitoring
					</MenuListItem>
					<MenuListItem
						icon={<LocationIcon />}
						selectedIcon={<LocationBoldIcon />}
						href="/location-tracking"
					>
						Location tracking
					</MenuListItem>
				</MenuList>
			</Box>
			<MenuList>
				<MenuListItem className="sidebar__logout" icon={<ExitIcon />} onClick={onLogout}>
					Log out
				</MenuListItem>
			</MenuList>
			<Box mt={2} px={0.5}>
				<ProfileCard className="sidebar__profile" initials={shortInitials} userName={userName} />
			</Box>
			<Box mt={2} display="flex" justifyContent={isSidebarOpen ? "end" : "center"}>
				<IconButton onClick={onSidebarToggle} disableRipple>
					{isSidebarOpen ? <DoubleArrowsLeftIcon /> : <DoubleArrowsRightIcon />}
				</IconButton>
			</Box>
		</StyledSidebar>
	);
};
