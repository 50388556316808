import type { ICellRendererParams } from "ag-grid-community";

import { AndroidIcon, AppleIcon } from "@icons/index";

import { StyledOSVersionRenderer } from "./StyledOSVersionRenderer";

export const OSVersionRenderer = (props: ICellRendererParams) => {
	const value = props?.value;

	const isAndroid = typeof value === "string" && value.includes("Android");
	const isIOS = typeof value === "string" && value.includes("iOS");

	return (
		<StyledOSVersionRenderer value={value}>
			{isAndroid && <AndroidIcon />}
			{isIOS && <AppleIcon />}
			{value}
		</StyledOSVersionRenderer>
	);
};
