import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import { Box } from "@mui/material";

export const StyledDeviceMarker = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		border: 1px solid ${theme?.palette?.customColors.blue100};
		border-radius: 50%;
		font-size: 24px;
		font-weight: normal;
		color: #000000;
		background-color: #fefefe;
	`}
`;
