import type { ChangeEvent } from "react";

import { useFormik } from "formik";

import { FormHeader } from "@components/FormHeader/FormHeader";
import { Input, InputFieldEnum } from "@components/Input/Input";
import { LoadingSpinner } from "@components/LoadingSpinner/LoadingSpinner";
import { LoadingStatusEnum, SUPPORT_LINK } from "@constants";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Alert, Box, Button } from "@mui/material";
import { formValidationSchema } from "@pages/Login/Form/formValidationSchema";
import { useUser } from "@store/userContext";
import type { LoginFormFieldsType } from "@type/AuthTypes";

const FORM_HEADER_TITLE = "Log in to your account";
const FORM_HEADER_CONTENT = "Good to have you back!";

export const Form = () => {
	const { onLogin, loginLoadingStatus } = useUser();
	const isError = loginLoadingStatus === LoadingStatusEnum.ERROR;
	const isLoading = loginLoadingStatus === LoadingStatusEnum.LOADING;

	const initialFormValues: LoginFormFieldsType = {
		username: "",
		password: "",
	};

	const { values, handleSubmit, setFieldTouched, setFieldValue, errors, touched } = useFormik({
		initialValues: initialFormValues,
		validateOnChange: true,
		validateOnMount: true,
		validationSchema: formValidationSchema,
		onSubmit: onLogin,
	});

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFieldValue(name, value, true);
		setFieldTouched(name, true, false);
	};

	return (
		<form onSubmit={handleSubmit}>
			<FormHeader mb={10} title={FORM_HEADER_TITLE} content={FORM_HEADER_CONTENT} />
			<Box display="flex" gap={2} mb={5}>
				<Box flex={1}>
					<Input
						name="username"
						type={InputFieldEnum.EMAIL}
						placeholder="Email"
						error={touched.username && !!errors.username}
						helperText={touched.username && errors.username}
						value={values.username}
						onChange={onChange}
					/>
				</Box>
				<Box flex={1}>
					<Input
						name="password"
						placeholder="Password"
						type={InputFieldEnum.PASSWORD}
						error={touched.password && !!errors.password}
						helperText={touched.password && errors.password}
						value={values.password}
						onChange={onChange}
					/>
				</Box>
			</Box>
			{isError && (
				<Box mb={2}>
					<Alert icon={<ErrorOutlineIcon />} severity="error">
						Wrong email or password
					</Alert>
				</Box>
			)}
			<Box mb={4}>
				<Button fullWidth variant="contained" color="primary" type="submit" disabled={isLoading}>
					{isLoading ? <LoadingSpinner /> : <>Log In</>}
				</Button>
			</Box>
			<Box display="flex" justifyContent="center">
				<Button disableRipple variant="text" color="primary" href={SUPPORT_LINK}>
					Support
				</Button>
			</Box>
		</form>
	);
};
