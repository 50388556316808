import type { RefObject } from "react";

import cx from "classnames";

import { Box } from "@mui/material";
import { Dropdown } from "@pages/LocationTracking/Devices/Dropdown/Dropdown";
import { StyledDevices } from "@pages/LocationTracking/Devices/StyledDevices";
import { Toggle } from "@pages/LocationTracking/Devices/Toggle/Toggle";
import type { LocationTrackingDeviceType } from "@type/LocationTrackingTypes";

type DevicesProps = {
	isOpen: boolean;
	onToggle: () => void;
	topOffset: number;
	devicesScrollBarRef: RefObject<HTMLDivElement | null>;
	devices: LocationTrackingDeviceType[];
	isFilterOpen: boolean;
	selectedDevice: LocationTrackingDeviceType | null;
	onDeviceChange: (val: LocationTrackingDeviceType) => void;
};

export const Devices = ({
	isOpen,
	onToggle,
	devicesScrollBarRef,
	devices,
	isFilterOpen,
	topOffset,
	selectedDevice,
	onDeviceChange,
}: DevicesProps) => {
	const isSidebarOpen = true;
	const devicesQty = devices.length > 1000 ? "1000+" : devices.length;

	return (
		<StyledDevices
			className={cx("location-tracking__devices", {
				isSidebarCollapsed: !isSidebarOpen,
				isFilterOpen: isFilterOpen,
				isOpen,
			})}
			py={2.5}
		>
			<Box px={3}>
				<Toggle appendLabel={devicesQty} label="Devices" isOpen={isOpen} onClick={onToggle} />
			</Box>
			{isOpen && (
				<Dropdown
					topOffset={topOffset}
					devicesScrollBarRef={devicesScrollBarRef}
					devices={devices}
					selectedDevice={selectedDevice}
					onDeviceChange={onDeviceChange}
				/>
			)}
		</StyledDevices>
	);
};
