import { Box } from "@mui/material";
import { DeviceItem } from "@pages/LocationTracking/Devices/DeviceItem/DeviceItem";
import type { LocationTrackingDeviceType } from "@type/LocationTrackingTypes";

type DevicesListProps = {
	devices: LocationTrackingDeviceType[];
	selectedDevice: LocationTrackingDeviceType | null;
	onDeviceChange: (val: LocationTrackingDeviceType) => void;
};

export const DevicesList = ({ devices, selectedDevice, onDeviceChange }: DevicesListProps) => (
	<Box px={3}>
		{devices.length > 0 ? (
			devices.map((device) => (
				<DeviceItem
					key={device.uuid}
					selectedDevice={selectedDevice}
					onDeviceChange={onDeviceChange}
					{...device}
				/>
			))
		) : (
			<div>The device list is empty.</div>
		)}
	</Box>
);
