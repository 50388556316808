import cx from "classnames";

import { BadgeCounter } from "@components/BadgeCounter/BadgeCounter";
import { DownloadIcon, FilterBoldIcon, FilterIcon } from "@icons/index";
import { Box, Button } from "@mui/material";

type DefaultHeaderCTAProps = {
	isFilterOpen: boolean;
	onFilterToggle: () => void;
	isCSVExportPending?: boolean;
	onExportToCSV?: () => void;
	selectedFilterCount?: number;
};

export const DefaultHeaderCTA = ({
	isFilterOpen,
	onFilterToggle,
	isCSVExportPending,
	onExportToCSV,
	selectedFilterCount = 0,
}: DefaultHeaderCTAProps) => {
	const isFiltersSelected = selectedFilterCount > 0;
	const isActive = isFiltersSelected || isFilterOpen;
	const filterIcon = isActive ? <FilterBoldIcon /> : <FilterIcon />;

	return (
		<Box display="flex" gap={3}>
			<Button
				disableRipple
				className={cx({ isActive })}
				startIcon={filterIcon}
				onClick={onFilterToggle}
			>
				<Box mr={isFiltersSelected ? 1 : 0} component="span">
					Filter
				</Box>
				{isFiltersSelected && <BadgeCounter count={selectedFilterCount} />}
			</Button>
			<Button
				variant="text"
				disableRipple
				startIcon={<DownloadIcon />}
				onClick={onExportToCSV}
				disabled={isCSVExportPending}
			>
				Download .CSV
			</Button>
		</Box>
	);
};
