import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import Box from "@mui/material/Box";

export const StyledDashboardLayout = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		min-height: 100vh;
		display: flex;

		&.isCollapsed .dashboard__sidebar {
			width: 88px;
		}

		&.isCollapsed .dashboard__container {
			margin-left: 88px;
		}

		.dashboard__sidebar {
			width: 291px;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			background: ${theme?.palette?.customColors.white};
			z-index: 1;
		}

		.dashboard__container {
			margin-left: 291px;
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			background: ${theme?.palette?.customColors.grey100};
		}

		.dashboard__container--body {
			display: flex;
			flex: 1;
			flex-direction: column;
		}
	`}
`;
