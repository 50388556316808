import type { TooltipProps } from "@mui/material";
import { Tooltip as MuiTooltip } from "@mui/material";

type CustomTooltipProps = {
	isDarkMode?: boolean;
} & TooltipProps;

export const Tooltip = ({ isDarkMode, children, ...rest }: CustomTooltipProps) => (
	<MuiTooltip
		{...rest}
		arrow
		placement="top"
		slotProps={{
			tooltip: {
				sx: {
					bgcolor: isDarkMode ? "rgba(97, 97, 97, 0.92)" : "white",
					color: isDarkMode ? "white" : "black",
					fontSize: 14,
				},
			},
			arrow: {
				sx: {
					color: isDarkMode ? "rgba(97, 97, 97, 0.92)" : "white",
				},
			},
		}}
	>
		{children}
	</MuiTooltip>
);
