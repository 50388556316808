import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import { Box } from "@mui/material";

export const StyledFormHeader = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		.form-header__title {
			font-size: ${theme?.customTypography?.fontSize.fs46};
			font-weight: ${theme?.customTypography?.fontWeight.bold};
			line-height: ${theme?.customTypography?.lineHeight.lh64};
			color: ${theme?.palette?.customColors.black100};
		}

		.form-header__content {
			font-size: ${theme?.customTypography?.fontSize.fs20};
			line-height: ${theme?.customTypography?.lineHeight.lh28};
			letter-spacing: ${theme?.customTypography?.letterSpacing.ls06};
		}
	`}
`;
