import { StyledLoadingSpinner } from "@components/LoadingSpinner/StyledLoadingSpinner";

export const LoadingSpinner = () => (
	<StyledLoadingSpinner>
		<div />
		<div />
		<div />
		<div />
	</StyledLoadingSpinner>
);
