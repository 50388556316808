import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { DefaultHeaderCTA } from "@components/DefaultHeaderCTA/DefaultHeaderCTA";
import { Filters } from "@components/Filters/Filters";
import { Table } from "@components/Table/Table";
import { ToasterMessage } from "@components/ToasterMessage/ToasterMessage";
import { TABLE_FOR_PAGE } from "@constants";
import { useActivityTrackingCSVExport } from "@hooks/useActivityTrackingCSVExport";
import { useUserActivityTrackingFilter } from "@hooks/useActivityTrackingFilter";
import { useActivityTrackingList } from "@hooks/useActivityTrackingList";
import { DashboardLayout } from "@layout/DashboardLayout/DashboardLayout";

const PAGE_TITLE = "User activity tracking";

export const UserActivityTracking = () => {
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const { isPending, isError, onCSVDownload } = useActivityTrackingCSVExport();
	const { filters, selectedFiltersCount, onClearAllFilters } = useUserActivityTrackingFilter();
	const { data, page, pageSize, onPageChange, onPageSizeChange, sortParams, osSortChange } =
		useActivityTrackingList();

	const onFilterToggle = () => setIsFilterOpen((prev) => !prev);

	const { gridOptions, detailGridOptions } = TABLE_FOR_PAGE.USER_ACTIVITY_TRACKING;

	useEffect(() => {
		if (isError) {
			toast.error(<ToasterMessage title="Something went wrong" message="" />);
		}
	}, [isError]);

	return (
		<DashboardLayout
			headerTitle={PAGE_TITLE}
			headerAppendRight={
				<DefaultHeaderCTA
					isFilterOpen={isFilterOpen}
					onFilterToggle={onFilterToggle}
					isCSVExportPending={isPending}
					onExportToCSV={onCSVDownload}
					selectedFilterCount={selectedFiltersCount}
				/>
			}
			headerAppendBottom={
				<Filters isOpen={isFilterOpen} filters={filters} onClearAll={onClearAllFilters} />
			}
		>
			<Table
				gridOptions={gridOptions}
				detailGridOptions={detailGridOptions}
				rowData={data?.content ?? []}
				totalRowCount={data?.totalElements ?? 0}
				page={page}
				setPage={onPageChange}
				pageSize={pageSize}
				setPageSize={onPageSizeChange}
				sortParams={sortParams}
				setSortParams={osSortChange}
			/>
		</DashboardLayout>
	);
};
