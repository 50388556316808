import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, type Theme } from "@mui/material";

export const StyledPageNotFound = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		.page-container {
			max-width: 700px;
		}

		.title {
			font-size: ${theme?.customTypography?.fontSize.fs46};
			font-weight: ${theme?.customTypography?.fontWeight.bold};
			line-height: ${theme?.customTypography?.lineHeight.lh64};
			color: ${theme?.palette?.customColors.black100};
		}

		.content {
			font-size: ${theme?.customTypography?.fontSize.fs20};
			line-height: ${theme?.customTypography?.lineHeight.lh28};
			letter-spacing: ${theme?.customTypography?.letterSpacing.ls06};
		}
	`}
`;
