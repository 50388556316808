export const defaultComparator = (valueA: string, valueB: string) => {
	if (valueA == null) return -1;
	if (valueB == null) return 1;
	return valueA.localeCompare(valueB);
};

export const memoryCapacityComparator = (valueA: string, valueB: string) => {
	if (valueA == null) return -1;
	if (valueB == null) return 1;

	const numA = parseInt(valueA.replace(" GB", ""), 10);
	const numB = parseInt(valueB.replace(" GB", ""), 10);

	return numA - numB;
};

export const numericStringComparator = (valueA: string | number, valueB: string | number) => {
	if (valueA == null) return -1;
	if (valueB == null) return 1;

	const numA = typeof valueA === "string" ? parseFloat(valueA) : valueA;
	const numB = typeof valueB === "string" ? parseFloat(valueB) : valueB;

	return numA - numB;
};
