import InputMask from "react-input-mask";

import { Input } from "@components/Input/Input";
import type { TextFieldProps } from "@mui/material";
import { Box } from "@mui/material";

interface MaskedValueChangeState {
	value: string;
	selection: {
		start: number;
		end: number;
	};
}

type DateInputProps = TextFieldProps & {
	defaultValue?: string;
	value?: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const DateInput = (props: DateInputProps) => {
	const formatChars = {
		D: "[0-3]",
		d: "[0-9]",
		M: "[0-1]",
		m: "[0-9]",
		Y: "[0-9]",
	};

	const beforeMaskedValueChange = (newState: MaskedValueChangeState) => {
		let { value } = newState;
		const dateParts = value.split(".");
		const dayPart = dateParts[0];
		const monthPart = dateParts[1];

		if (dayPart?.startsWith("3")) {
			formatChars["d"] = "[0-1]";
		} else {
			formatChars["d"] = "[0-9]";
		}

		if (monthPart?.startsWith("1")) {
			formatChars["m"] = "[0-2]";
		} else {
			formatChars["m"] = "[1-9]";
		}

		return { value, selection: newState.selection };
	};

	return (
		<InputMask
			mask="Dd.Mm.YYYY"
			placeholder="DD.MM.YYYY"
			formatChars={formatChars}
			beforeMaskedValueChange={beforeMaskedValueChange}
			defaultValue={props.defaultValue ?? ""}
			onChange={props.onChange}
			value={props.value}
		>
			{/* @ts-ignore */}
			{(inputProps) => (
				<Box width="120px">
					<Input {...inputProps} {...props} />
				</Box>
			)}
		</InputMask>
	);
};
