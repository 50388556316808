import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledToasterMessage = styled.div`
	${({ theme }: { theme?: Theme }) => css`
		font-size: ${theme?.customTypography?.fontSize?.fs14};
		line-height: ${theme?.customTypography?.lineHeight?.lh19};

		.toaster__title {
			font-weight: ${theme?.customTypography?.fontWeight?.semiBold};
		}
	`}
`;
