import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

export enum DayBoundaryEnum {
	DAY_START = "DAY_START",
	DAY_END = "DAY_END",
}

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const getFormattedDateAndTimeToUTCWithTimezone = (
	date: string,
	time?: string,
	boundary: DayBoundaryEnum = DayBoundaryEnum.DAY_START,
): string => {
	let formattedDate = dayjs.tz(date, "DD.MM.YYYY", "UTC");

	if (!time) {
		time = boundary === DayBoundaryEnum.DAY_START ? "00:00" : "23:59";
	}

	const [hours, minutes] = time.split(":");
	formattedDate = formattedDate.hour(Number(hours)).minute(Number(minutes));

	if (boundary === DayBoundaryEnum.DAY_START) {
		return formattedDate.startOf("minute").utc().format("YYYY-MM-DD HH:mm:ss[+00]");
	} else if (boundary === DayBoundaryEnum.DAY_END) {
		return formattedDate.endOf("minute").second(59).utc().format("YYYY-MM-DD HH:mm:ss[+00]");
	}

	return formattedDate.utc().format("YYYY-MM-DD HH:mm:ss[+00]");
};
