import type { SVGProps } from "react";

const StatusActiveIcon = ({ width = "24", height = "24", ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<g filter="url(#filter0_f_2921_4116)">
			<circle cx="16" cy="16" r="12" fill="#BEF3D6" />
		</g>
		<circle cx="16" cy="16" r="4" fill="#27D876" />
		<defs>
			<filter
				id="filter0_f_2921_4116"
				x="0"
				y="0"
				width="32"
				height="32"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2921_4116" />
			</filter>
		</defs>
	</svg>
);

export default StatusActiveIcon;
