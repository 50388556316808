import * as Yup from "yup";

const emailRegex =
	/^(?=.{6,55}$)[a-zA-Z0-9]+([._~+-]*[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;

export const formValidationSchema = Yup.object().shape({
	username: Yup.string()
		.min(6, "Please enter a valid email")
		.max(55, "Please enter a valid email")
		.matches(emailRegex, "Please enter a valid email")
		.required("This field is required"),
	password: Yup.string().required("This field is required"),
});
