export const cssVars = {
	fontFamily: {
		redHatDisplay: "Red Hat Display, sans-serif",
	},
	fontWeight: {
		regular: 400,
		semiBold: 600,
		bold: 700,
	},
	fontSize: {
		fs96: "6rem",
		fs60: "3.75rem",
		fs48: "3rem",
		fs46: "2.875rem",
		fs34: "2.125rem",
		fs24: "1.5rem",
		fs20: "1.25rem",
		fs16: "1rem",
		fs14: "0.875rem",
		fs12: "0.75rem",
	},
	lineHeight: {
		lh134: "8.4rem",
		lh84: "5.25rem",
		lh67: "4.2rem",
		lh64: "4.025rem",
		lh47: "2.975rem",
		lh33: "2.1rem",
		lh28: "1.75rem",
		lh22: "1.4rem",
		lh21: "1.374rem",
		lh19: "1.225rem",
		lh16: "1.05rem",
	},
	letterSpacing: {
		ls12: "0.075rem",
		ls1: "0.0625rem",
		ls06: "0.0375rem",
		ls03: "0.01875rem",
	},
	color: {
		white: "#FFFFFF",
		black100: "#101218",
		black200: "#333333",
		red100: "#E03616",
		red200: "#FF674D",
		red300: "#FFD3CC",
		green100: "#27D876",
		green200: "#BEF3D6",
		green300: "#EEFFE5",
		grey100: "#F2F3F5",
		grey200: "#E9EEF3",
		grey300: "#D0D5DE",
		grey400: "#BABFC7",
		grey500: "#999999",
		grey600: "#666666",
		blue100: "#444C66",
		blue200: "#1775CF",
		blue300: "#1C90FF",
		blue400: "#9DCFFF",
		blue500: "#C9E4FF",
		blue600: "#E8F4FF",
		purple100: "#F4EFFB",
	},
};
