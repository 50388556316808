export const getShortInitials = (firstname: string, lastname: string): string => {
	if (!firstname || !lastname) {
		return "--";
	}

	const firstInitial = firstname.charAt(0).toUpperCase();
	const lastInitial = lastname.charAt(0).toUpperCase();

	return `${firstInitial}${lastInitial}`;
};
