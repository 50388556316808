import dayjs from "dayjs";

import { Tooltip } from "@components/Tooltip/Tooltip";
import { InactiveMoveStatusIcon } from "@icons/index";
import { Box } from "@mui/material";
import { StyledDeviceInfoRow } from "@pages/LocationTracking/LocationTrackingMap/DeviceMarker/DeviceInfoRow/StyledDeviceInfoRow";

type DeviceInfoRowProps = {
	label?: string;
	address: string;
	lastUpdate: string;
};

export const DeviceInfoRow = ({ label, address, lastUpdate }: DeviceInfoRowProps) => {
	const formattedDate = dayjs(lastUpdate).utc().format("hh:mm:ss A DD/MM/YYYY");

	return (
		<StyledDeviceInfoRow>
			{label && (
				<Box mt={2}>
					<Box className="device-info__indicator" display="inline-flex">
						<InactiveMoveStatusIcon />
					</Box>
					<Box className="device-info__label">{label}</Box>
				</Box>
			)}
			<Box mt={1}>
				<Box className="device-info__row-label">Address:</Box>
				<Box className="device-info__row-content">{address}</Box>
			</Box>
			<Box mt={1}>
				<Box className="device-info__row-label">Last update:</Box>
				<Box className="device-info__row-content">
					<Tooltip title="Device local time is displayed" isDarkMode={true}>
						<span>{formattedDate}</span>
					</Tooltip>
				</Box>
			</Box>
		</StyledDeviceInfoRow>
	);
};
