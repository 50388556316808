import { axiosInstance } from "@services/axios";

type GetDataUsageMonitoringListRequestType = {
	imei?: string;
	device?: string;
	application?: string;
	datafrom?: string;
	datato?: string;
	sortField?: string;
	sortOrder?: string;
	page: string;
	size: string;
};

export const getDataUsageMonitoringList = async ({
	imei,
	device,
	application,
	datafrom,
	datato,
	sortField,
	sortOrder,
	page,
	size,
}: GetDataUsageMonitoringListRequestType) => {
	const params = new URLSearchParams();

	if (imei) params.append("imei", imei);
	if (device) params.append("device", device);
	if (application) params.append("application", application);
	if (datafrom) params.append("datefrom", datafrom);
	if (datato) params.append("dateto", datato);
	if (sortField) params.append("sortField", sortField);
	if (sortOrder) params.append("sortOrder", sortOrder);
	if (page) params.append("page", page);
	if (size) params.append("size", size);

	return await axiosInstance
		.get(`page/monitoring/details?${params.toString()}`, {})
		.then(({ data }) => data);
};
