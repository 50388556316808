import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

import type { ChangeEvent, MouseEvent } from "react";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Toggle } from "@components/Toggle/Toggle";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import { EndIcon, NextIcon, PrevIcon, StartIcon } from "@icons/index";
import { Box, Typography } from "@mui/material";
import { getDateRangeLabel } from "@services/getDateRangeLabel";
import type { Value } from "@wojtekmaj/react-daterange-picker/dist/cjs/shared/types";

import { Button } from "./Button/Button";
import { Container } from "./Container/Container";
import { DateInput } from "./DateInput/DateInput";
import { StyledDatePicker } from "./StyledDatePicker";
import { TimeInput } from "./TimeInput/TimeInput";
dayjs.extend(customParseFormat);

export type DatePickerValueType = {
	dateFrom: string;
	dateTo: string;
	timeFrom?: string;
	timeTo?: string;
} | null;

type DatePickerProps = {
	className: string;
	value?: DatePickerValueType;
	placeholder: string;
	onChange: (val: DatePickerValueType) => void;
	isTimeRange?: boolean;
};

export const DatePicker = forwardRef(
	({ className, placeholder, value, onChange, isTimeRange }: DatePickerProps, ref) => {
		const [dateRange, setDateRange] = useState<Value>(
			value
				? [dayjs(value.dateFrom, "DD.MM.YYYY").toDate(), dayjs(value.dateTo, "DD.MM.YYYY").toDate()]
				: null,
		);
		const [timeRange, setTimeRange] = useState<[string, string] | null>(
			value?.timeFrom && value?.timeTo
				? [
						dayjs(value.timeFrom, "HH:mm:ss").format("hh:mm A"),
						dayjs(value.timeTo, "HH:mm:ss").format("hh:mm A"),
					]
				: null,
		);

		const [startDate, setStartDate] = useState<string | undefined>(
			value ? value.dateFrom : undefined,
		);
		const [endDate, setEndDate] = useState<string | undefined>(value ? value.dateTo : undefined);
		const [startDateError, setStartDateError] = useState("");
		const [endDateError, setEndDateError] = useState("");
		const [dateError, setDateError] = useState("");

		const [startTime, setStartTime] = useState<string>(
			value?.timeFrom ? dayjs(value.timeFrom, "HH:mm:ss").format("hh:mm A") : "12:00 AM",
		);
		const [endTime, setEndTime] = useState<string>(
			value?.timeTo ? dayjs(value.timeTo, "HH:mm:ss").format("hh:mm A") : "11:59 PM",
		);
		const [startTimeError, setStartTimeError] = useState("");
		const [endTimeError, setEndTimeError] = useState("");

		const [calendarActiveStartDate, setCalendarActiveStartDate] = useState<Date | undefined>(
			value ? dayjs(value.dateTo, "DD.MM.YYYY").toDate() : new Date(),
		);

		const [isCalendarVisible, setIsCalendarVisible] = useState(false);
		const toggleRef = useRef<HTMLButtonElement | null>(null);
		const popperRef = useRef<HTMLDivElement | null>(null);
		const toggleLabel = dateRange ? getDateRangeLabel(dateRange, timeRange) : "";
		const buttonsList = [
			"Today",
			"Yesterday",
			"Last 7 days",
			"Last 30 days",
			"This month",
			"Last month",
			"Last year",
		];

		useImperativeHandle(ref, () => ({
			clear: () => handleClear(),
		}));

		useEffect(() => {
			if (!Array.isArray(dateRange) || dateRange.length !== 2) {
				return;
			}

			let [startDate, endDate] = dateRange;
			const formattedStartDate = dayjs(startDate).format("DD.MM.YYYY");
			const formattedEndDate = dayjs(endDate).format("DD.MM.YYYY");

			if (isTimeRange && timeRange) {
				let [timeFrom, timeTo] = timeRange;
				timeFrom = dayjs(timeFrom, "hh:mm A").format("HH:mm:ss");
				timeTo = dayjs(timeTo, "hh:mm A").format("HH:mm:ss");
				onChange({
					dateFrom: formattedStartDate,
					dateTo: formattedEndDate,
					timeFrom: timeFrom,
					timeTo: timeTo,
				});
			} else {
				onChange({ dateFrom: formattedStartDate, dateTo: formattedEndDate });
			}
		}, [dateRange, timeRange, isTimeRange, onChange]);

		const handleChange = (newValue: Value) => {
			setDateRange(newValue);
			clearErrors();
			setStartDate(getStartDate(newValue));
			setEndDate(getEndDate(newValue));

			if (Array.isArray(newValue) && newValue[0]) {
				setCalendarActiveStartDate(newValue[0]);
			}

			setIsCalendarVisible(false);
		};

		const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
			const button = event.target as HTMLButtonElement;
			let startDate: Date | undefined;
			let endDate: Date | undefined;

			switch (button.textContent) {
				case "Today": {
					startDate = dayjs().toDate();
					endDate = startDate;
					break;
				}
				case "Yesterday": {
					startDate = dayjs().subtract(1, "day").toDate();
					endDate = startDate;
					break;
				}
				case "Last 7 days": {
					startDate = dayjs().subtract(6, "day").toDate();
					endDate = dayjs().toDate();
					break;
				}
				case "Last 30 days": {
					startDate = dayjs().subtract(29, "day").toDate();
					endDate = dayjs().toDate();
					break;
				}
				case "This month": {
					startDate = dayjs().startOf("month").toDate();
					endDate = dayjs().toDate();
					break;
				}
				case "Last month": {
					startDate = dayjs().subtract(1, "month").startOf("month").toDate();
					endDate = dayjs().subtract(1, "month").endOf("month").toDate();
					break;
				}
				case "Last year": {
					startDate = dayjs().subtract(1, "year").startOf("year").toDate();
					endDate = dayjs().subtract(1, "year").endOf("year").toDate();
					break;
				}
				case "Custom dates": {
					startDate = dayjs().subtract(5, "day").toDate();
					endDate = dayjs().toDate();
					break;
				}
				default:
					break;
			}

			if (startDate && endDate) {
				setDateRange([startDate, endDate]);
				setStartDate(getStartDate([startDate, endDate]));
				setEndDate(getEndDate([startDate, endDate]));
				setCalendarActiveStartDate(startDate);
			}
			if (button.textContent !== "Custom dates") {
				if (isTimeRange) setTimeRange([startTime, endTime]);
				setIsCalendarVisible(false);
				clearErrors();
			}
			resetTmeRange();
		};

		const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
			setStartDateError("");
			setDateError("");
			const newValue = e.target.value;
			setStartDate(newValue);
			const newStartDate = dayjs(newValue, "DD.MM.YYYY");
			const oldEndDate = dayjs(endDate, "DD.MM.YYYY");
			if (newStartDate.isValid()) {
				const today = dayjs();
				if (newStartDate.isAfter(today)) {
					setStartDateError("Start date can't be in the future");
					return;
				}
				if (newStartDate.isAfter(oldEndDate)) {
					setDateError("Start date can't be after end date");
					return;
				}
				if (endDateError || !oldEndDate.isValid()) return;
				setDateRange([newStartDate.toDate(), dayjs(endDate, "DD.MM.YYYY").toDate()]);
				setCalendarActiveStartDate(newStartDate.toDate());
			}
		};

		const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
			setEndDateError("");
			setDateError("");
			const newValue = e.target.value;
			setEndDate(newValue);
			const newEndDate = dayjs(newValue, "DD.MM.YYYY");
			const oldStartDate = dayjs(startDate, "DD.MM.YYYY");
			if (newEndDate.isValid()) {
				const today = dayjs();
				if (newEndDate.isAfter(today)) {
					setEndDateError("End date can't be in the future");
					return;
				}
				if (oldStartDate.isAfter(newEndDate)) {
					setDateError("End date can't be before the start date");
					return;
				}
				if (startDateError || !oldStartDate.isValid()) return;
				setDateRange([dayjs(startDate, "DD.MM.YYYY").toDate(), newEndDate.toDate()]);
				setCalendarActiveStartDate(newEndDate.toDate());
			}
		};

		const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
			setStartTimeError("");
			const newValue = e.target.value.toUpperCase();
			setStartTime(newValue);
			if (isTimeValid(newValue)) {
				const dayjsStartTime = dayjs(newValue.toUpperCase(), "hh:mm A");
				const dayjsEndTime = dayjs(endTime, "hh:mm A");
				const isSameDay = dayjs(startDate, "DD.MM.YYYY").isSame(
					dayjs(endDate, "DD.MM.YYYY"),
					"day",
				);
				if (isSameDay && dayjsStartTime.isAfter(dayjsEndTime)) {
					setStartTimeError("Start time can't be after end time");
					return;
				} else setEndTimeError("");

				setTimeRange([newValue, endTime]);
			}
		};

		const handleEndTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
			setEndTimeError("");
			const newValue = e.target.value.toUpperCase();
			setEndTime(newValue);
			if (isTimeValid(newValue)) {
				const dayjsStartTime = dayjs(startTime, "hh:mm A");
				const dayjsEndTime = dayjs(newValue, "hh:mm A");
				const isSameDay = dayjs(startDate, "DD.MM.YYYY").isSame(
					dayjs(endDate, "DD.MM.YYYY"),
					"day",
				);
				if (isSameDay && dayjsEndTime.isBefore(dayjsStartTime)) {
					setEndTimeError("End time can't be before start time");
					return;
				} else setStartTimeError("");

				setTimeRange([startTime, newValue]);
			}
		};

		const isTimeValid = (value: string) => value.length === 8 && !value.includes("_");

		const resetTmeRange = () => {
			setStartTime("12:00 AM");
			setEndTime("11:59 PM");
			setTimeRange(null);
		};

		const clearErrors = () => {
			setDateError("");
			setStartDateError("");
			setEndDateError("");
		};

		const handleClear = () => {
			setDateRange(null);
			setTimeRange(null);
			setStartDate(undefined);
			setEndDate(undefined);
			setStartTime("12:00 AM");
			setEndTime("11:59 PM");
			clearErrors();
			onChange(null);
		};

		const onClose = () => {
			setIsCalendarVisible(false);
		};

		const handleToggle = () => {
			setIsCalendarVisible((prev) => !prev);
		};

		const getStartDate = (val: Value): string | undefined => {
			if (Array.isArray(val) && val[0]) {
				return dayjs(val[0]).format("DD.MM.YYYY");
			}
			return undefined;
		};

		const getEndDate = (val: Value): string | undefined => {
			if (Array.isArray(val) && val[1]) {
				return dayjs(val[1]).format("DD.MM.YYYY");
			}
			return undefined;
		};

		useOnClickOutside(popperRef, onClose, true, [toggleRef]);

		return (
			<div className={className}>
				<Toggle
					className=""
					selectedDateRange={toggleLabel}
					innerRef={toggleRef}
					isOpen={isCalendarVisible}
					label={placeholder}
					placeholder="Date"
					onToggle={handleToggle}
					onClear={handleClear}
					isError={false}
				/>
				<Container visible={isCalendarVisible} ref={popperRef}>
					<Box display="flex" flexDirection="column">
						{buttonsList.map((menuItem) => (
							<Button
								variant="text"
								color="primary"
								active={toggleLabel === menuItem}
								key={menuItem}
								onClick={handleClick}
							>
								{menuItem}
							</Button>
						))}
						<Button
							variant="text"
							color="primary"
							active={toggleLabel && !buttonsList.includes(toggleLabel)}
							onClick={handleClick}
						>
							Custom dates
						</Button>
					</Box>
					<div className="divider" />
					<Box display="flex" flexDirection="column" justifyContent="space-between">
						<StyledDatePicker
							isOpen={true}
							shouldCloseCalendar={() => false}
							onChange={handleChange}
							value={dateRange}
							calendarProps={{
								nextLabel: <NextIcon />,
								prevLabel: <PrevIcon />,
								prev2Label: <StartIcon />,
								next2Label: <EndIcon />,
								locale: "en-US",
								maxDate: new Date(),
								activeStartDate: calendarActiveStartDate,
								onActiveStartDateChange: ({ activeStartDate }) =>
									setCalendarActiveStartDate(activeStartDate ?? undefined),
							}}
						/>

						{toggleLabel && !buttonsList.includes(toggleLabel) && (
							<Box display="flex" flexDirection="column" gap="8px" mt="8px">
								Date range
								<Box display="flex" alignItems="center" gap="4px">
									<DateInput
										value={startDate}
										onChange={handleStartDateChange}
										error={!!startDateError || !!dateError}
									/>
									<div className="dash" />
									<DateInput
										value={endDate}
										onChange={handleEndDateChange}
										error={!!endDateError || !!dateError}
									/>
								</Box>
								<Typography variant="body2" sx={{ color: "red" }}>
									{startDateError || endDateError || dateError}
								</Typography>
							</Box>
						)}
						{isTimeRange && toggleLabel && (
							<Box display="flex" flexDirection="column" gap="8px" mt="8px">
								Time range
								<Box display="flex" alignItems="center" gap="4px">
									<TimeInput
										value={startTime}
										onChange={handleStartTimeChange}
										error={!!startTimeError}
									/>
									<div className="dash" />
									<TimeInput
										value={endTime}
										onChange={handleEndTimeChange}
										error={!!endTimeError}
									/>
								</Box>
								<Typography variant="body2" sx={{ color: "red" }}>
									{startTimeError || endTimeError}
								</Typography>
							</Box>
						)}
					</Box>
				</Container>
			</div>
		);
	},
);
