import { fetchAuthSession } from "aws-amplify/auth";
import dayjs from "dayjs";

export const getUserToken = async () => {
	try {
		const session = await fetchAuthSession();
		const idToken = session.tokens?.idToken?.toString() || null;
		const expirationTime = session.tokens?.accessToken.payload.exp || 0;
		const currentTime = dayjs().unix();
		return expirationTime > currentTime ? idToken : null;
	} catch (e) {
		return null;
	}
};
