import { axiosInstance } from "@services/axios";

type GetDataUsageMonitoringCSVRequestType = {
	imei?: string;
	device?: string;
	application?: string;
	datafrom?: string;
	datato?: string;
	sortField?: string;
	sortOrder?: string;
};

export const getDataUsageMonitoringCSV = async ({
	imei,
	device,
	application,
	datafrom,
	datato,
	sortField,
	sortOrder,
}: GetDataUsageMonitoringCSVRequestType) => {
	const params = new URLSearchParams();
	if (imei) params.append("imei", imei);
	if (device) params.append("device", device);
	if (application) params.append("application", application);
	if (datafrom) params.append("datefrom", datafrom);
	if (datato) params.append("dateto", datato);
	if (sortField) params.append("sortField", sortField);
	if (sortOrder) params.append("sortOrder", sortOrder);

	return await axiosInstance
		.get(`page/monitoring/details/csv?${params.toString()}`, {})
		.then(({ data }) => data);
};
