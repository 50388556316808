import { forwardRef, useEffect, useRef } from "react";

import cx from "classnames";
import type { Props as SimpleBarProps } from "simplebar-react";

import { StyledScrollBar } from "./StyledScrollBar";

type ScrollBarProps = SimpleBarProps & {
	isTrackBarTransparent?: boolean;
	onScrollToEnd?: () => void;
};

export type ScrollBarRef = {
	scrollToId: (id: string) => void;
};

export const ScrollBar = forwardRef<HTMLDivElement, ScrollBarProps>(
	({ className, isTrackBarTransparent, children, onScrollToEnd, ...props }, ref) => {
		const simpleBarRef = useRef(null);

		useEffect(() => {
			const scrollToId = (id: string) => {
				if (simpleBarRef.current) {
					//@ts-ignore
					const contentWrapper = simpleBarRef.current.getScrollElement();
					const element = contentWrapper.querySelector(`#${id}`);
					if (element) {
						element.scrollIntoView({ behavior: "smooth", block: "start" });
					}
				}
			};

			if (ref) {
				if (typeof ref === "function") {
					//@ts-ignore
					ref({ scrollToId });
				} else {
					(ref as React.MutableRefObject<ScrollBarRef | null>).current = { scrollToId };
				}
			}
		}, [ref]);

		return (
			<div>
				<StyledScrollBar
					ref={simpleBarRef}
					className={cx(className, { isTrackBarTransparent })}
					{...props}
				>
					{children}
				</StyledScrollBar>
			</div>
		);
	},
);
