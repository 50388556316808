import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";

import { v4 as uuidv4 } from "uuid";

import type { Cluster } from "@googlemaps/markerclusterer";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { InactiveMoveStatusIcon } from "@icons/index";
import { CustomCluster } from "@pages/LocationTracking/LocationTrackingMap/CustomCluster/CustomCluster";
import { DeviceMarker } from "@pages/LocationTracking/LocationTrackingMap/DeviceMarker/DeviceMarker";
import type { LocationTrackingDeviceType } from "@type/LocationTrackingTypes";
import { AdvancedMarker, useMap } from "@vis.gl/react-google-maps";

type MarkersProps = {
	devices: LocationTrackingDeviceType[];
	openDevice: string;
	selectedDevice: LocationTrackingDeviceType | null;
	onSelectMapDevice: (val: LocationTrackingDeviceType) => void;
};

const createClusterMarker = (
	position: google.maps.LatLng,
	label: string,
): google.maps.marker.AdvancedMarkerElement => {
	const markerContent = document.createElement("div");
	const root = createRoot(markerContent);
	root.render(<CustomCluster label={label} />);
	return new google.maps.marker.AdvancedMarkerElement({
		position,
		content: markerContent,
	});
};

function getPolygonPath(device: LocationTrackingDeviceType): google.maps.LatLngLiteral[] {
	if (!device.prevLocations) {
		return [];
	}
	const previousLocations = device.prevLocations.map((location) => ({
		lat: location.lat,
		lng: location.lng,
	}));

	const currentLocation = {
		lat: device.lat,
		lng: device.lng,
	};

	return [...previousLocations, currentLocation];
}

export const Markers = ({
	devices,
	openDevice,
	selectedDevice,
	onSelectMapDevice,
}: MarkersProps) => {
	const map = useMap();
	const [markers, setMarkers] = useState<{
		[key: string]: google.maps.marker.AdvancedMarkerElement;
	}>({});
	const clusters = useRef<MarkerClusterer | null>(null);
	const polylineRef = useRef<google.maps.Polyline | null>(null);

	useEffect(() => {
		if (!map) return;

		if (openDevice && selectedDevice) {
			const path = getPolygonPath(selectedDevice);

			if (polylineRef.current) {
				polylineRef.current.setMap(null);
			}

			if (path.length > 1) {
				polylineRef.current = new google.maps.Polyline({
					path,
					geodesic: true,
					strokeColor: "#FF674D",
					strokeOpacity: 1.0,
					strokeWeight: 2,
				});

				polylineRef.current.setMap(map);
			}
		} else {
			if (polylineRef.current) {
				polylineRef.current.setMap(null);
			}
		}
	}, [openDevice, selectedDevice, map]);

	useEffect(() => {
		if (!map) return;
		if (!clusters.current) {
			clusters.current = new MarkerClusterer({
				map,
				renderer: {
					render: (cluster: Cluster) =>
						createClusterMarker(cluster.position, String(cluster.count)),
				},
			});
		}
	}, [map]);

	useEffect(() => {
		if (!map || !clusters.current) return;

		clusters.current.clearMarkers();

		clusters.current.addMarkers(Object.values(markers));
	}, [map, markers]);

	useEffect(() => {
		setMarkers((prevMarkers) =>
			devices.reduce(
				(acc, device) => {
					if (prevMarkers[device.imei]) {
						acc[device.imei] = prevMarkers[device.imei];
					}
					return acc;
				},
				{} as { [key: string]: google.maps.marker.AdvancedMarkerElement },
			),
		);
	}, [devices]);

	const onSetMarkerRef = (
		marker: google.maps.marker.AdvancedMarkerElement | null,
		key: string,
		isOpen: boolean,
		openDeviceIMEI: string,
	) => {
		if (!marker) return;
		if (marker && markers[key]) return;
		if (!marker && !markers[key]) return;

		// //@ts-ignore
		setMarkers((prev) => {
			if (marker) {
				return { ...prev, [key]: marker };
			} else {
				const { [key]: _, ...newMarkers } = prev;
				return newMarkers;
			}
		});
	};

	return (
		<>
			{devices.map((device) => (
				<DeviceMarker
					key={device.imei}
					{...device}
					openDevice={openDevice}
					selectedDevice={selectedDevice}
					onSelectMapDevice={onSelectMapDevice}
					onSetMarkerRef={onSetMarkerRef}
					setMarkers={setMarkers}
				/>
			))}
			{openDevice &&
				selectedDevice?.prevLocations &&
				selectedDevice.prevLocations.length > 0 &&
				selectedDevice?.prevLocations.map((location, index) => (
					<AdvancedMarker
						key={uuidv4()}
						position={{ lat: location.lat, lng: location.lng }}
						title={`${location.address}`}
						style={{ marginBottom: "-16px" }}
					>
						<InactiveMoveStatusIcon />
					</AdvancedMarker>
				))}
		</>
	);
};
