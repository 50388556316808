import MenuItem from "@mui/material/MenuItem";
import type { SelectChangeEvent } from "@mui/material/Select";

import { Select } from "./Select/Select";
import { StyledSelectPageSize } from "./StyledSelectPageSize";

interface SelectPageSizeProps {
	pageSize: string;
	setPageSize: (value: string) => void;
}

export const SelectPageSize = ({ pageSize, setPageSize }: SelectPageSizeProps) => {
	const handleChange = (event: SelectChangeEvent<unknown>) => {
		setPageSize(event.target.value as string);
	};

	return (
		<StyledSelectPageSize>
			<Select value={pageSize} onChange={handleChange} autoWidth variant="outlined">
				<MenuItem value={"10"}>10</MenuItem>
				<MenuItem value={"20"}>20</MenuItem>
				<MenuItem value={"30"}>30</MenuItem>
				<MenuItem value={"40"}>40</MenuItem>
				<MenuItem value={"50"}>50</MenuItem>
			</Select>
		</StyledSelectPageSize>
	);
};
