import axios from "axios";

import { getUserToken } from "@helpers/getUserToken";

export const axiosInstance = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

export const setupInterceptors = (onUserReset: () => void) => {
	axiosInstance.interceptors.request.use(async (config) => {
		const token = await getUserToken();
		if (!token) {
			await onUserReset();
			return Promise.reject(new Error("Your session has expired. Please log in again."));
		}
		config.headers.Authorization = `Bearer ${token}`;
		return config;
	});
};
