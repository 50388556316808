import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { fetchUserAttributes, signIn, signOut } from "aws-amplify/auth";

import { ToasterMessage } from "@components/ToasterMessage/ToasterMessage";
import { LoadingStatusEnum } from "@constants";
import type { LoginFormFieldsType } from "@type/AuthTypes";
import type { UserType } from "@type/UserType";

type UserContextProps = {
	user: UserType | null;
	onLogin: (values: LoginFormFieldsType) => Promise<void>;
	loginLoadingStatus: LoadingStatusEnum;
	isAuth: boolean;
	sessionCheckLoadingStatus: LoadingStatusEnum;
	onLogout: () => void;
	userResetOnTokenExpiration: () => void;
	logoutLoadingStatus: LoadingStatusEnum;
};

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const useUser = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error("useUser must be used within a UserProvider");
	}
	return context;
};

type UserProviderProps = {
	children: ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
	const [user, setUser] = useState<UserType | null>(null);
	const [loginLoadingStatus, setLoginLoadingStatus] = useState<LoadingStatusEnum>(
		LoadingStatusEnum.INITIAL,
	);
	const [logoutLoadingStatus, setLogoutLoadingStatus] = useState<LoadingStatusEnum>(
		LoadingStatusEnum.INITIAL,
	);
	const [sessionCheckLoadingStatus, setSessionCheckLoadingStatus] = useState<LoadingStatusEnum>(
		LoadingStatusEnum.INITIAL,
	);
	const [isAuth, setIsAuth] = useState<boolean>(false);

	const onLogin = async (values: LoginFormFieldsType) => {
		setLoginLoadingStatus(LoadingStatusEnum.LOADING);
		const { username, password } = values;

		try {
			await signIn({ username, password });
			const userData = await fetchUserAttributes();
			const { sub, email, email_verified, name, family_name } = userData;
			setUser({
				id: sub,
				firstName: name ?? "",
				lastName: family_name ?? "",
				email: email ?? "",
				isEmailVerified: email_verified === "true" || false,
			});
			setIsAuth(true);
			localStorage.setItem("isLogged", JSON.stringify(true));
			toast.success(
				<ToasterMessage title="You have successfully logged into your account." message="" />,
			);
			setLoginLoadingStatus(LoadingStatusEnum.LOADED);
		} catch (e) {
			setIsAuth(false);
			setLoginLoadingStatus(LoadingStatusEnum.ERROR);
		}
	};

	const onLogout = async () => {
		setLogoutLoadingStatus(LoadingStatusEnum.LOADING);
		localStorage.setItem("isLogged", JSON.stringify(false));
		try {
			await signOut();
			setUser(null);
			setIsAuth(false);
			setLogoutLoadingStatus(LoadingStatusEnum.LOADED);
		} catch (e) {
			setUser(null);
			setIsAuth(false);
			toast.error(
				<ToasterMessage title="Something went wrong" message="Please try once again later." />,
			);
			setLogoutLoadingStatus(LoadingStatusEnum.ERROR);
		}
	};

	const userResetOnTokenExpiration = async () => {
		setLogoutLoadingStatus(LoadingStatusEnum.LOADING);
		const isLogged = JSON.parse(localStorage.getItem("isLogged") || "false");
		try {
			await signOut();
			setUser(null);
			setIsAuth(false);
			setLogoutLoadingStatus(LoadingStatusEnum.LOADED);
			if (isLogged) {
				toast.error(
					<ToasterMessage title="Your session has expired. Please log in again." message="" />,
				);
				localStorage.setItem("isLogged", JSON.stringify(false));
			}
		} catch (e) {
			setUser(null);
			setIsAuth(false);
			toast.error(
				<ToasterMessage title="Something went wrong" message="Please try once again later." />,
			);
			setLogoutLoadingStatus(LoadingStatusEnum.ERROR);
		}
	};

	useEffect(() => {
		const checkSession = async () => {
			setSessionCheckLoadingStatus(LoadingStatusEnum.LOADING);
			try {
				const userData = await fetchUserAttributes();
				const { sub, email, email_verified, name, family_name } = userData;
				setUser({
					id: sub,
					firstName: name ?? "",
					lastName: family_name ?? "",
					email: email ?? "",
					isEmailVerified: email_verified === "true" || false,
				});
				setIsAuth(true);
				setSessionCheckLoadingStatus(LoadingStatusEnum.LOADED);
			} catch (e) {
				const isLogged = JSON.parse(localStorage.getItem("isLogged") || "false");
				await signOut();
				setUser(null);
				setIsAuth(false);
				setLogoutLoadingStatus(LoadingStatusEnum.LOADED);
				setSessionCheckLoadingStatus(LoadingStatusEnum.ERROR);
				if (isLogged) {
					localStorage.setItem("isLogged", JSON.stringify(false));
					toast.error(
						<ToasterMessage title="Your session has expired. Please log in again." message="" />,
					);
				}
			}
		};
		checkSession();
	}, []);

	return (
		<UserContext.Provider
			value={{
				user,
				onLogin,
				loginLoadingStatus,
				isAuth,
				sessionCheckLoadingStatus,
				onLogout,
				logoutLoadingStatus,
				userResetOnTokenExpiration,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
