import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DayBoundaryEnum, getFormattedDateToUTCWithTimezone } from "@helpers/getFormattedDate";
import { useQuery } from "@tanstack/react-query";
import type { SortParamsType } from "@type/TableTypes";

import { getDataUsageMonitoringList } from "../api/getDataUsageMonitoringList";

const DEFAULT_PAGE = "1";
const DEFAULT_PAGE_SIZE = "10";

export const useDataUsageMonitoringList = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const sortField = searchParams.get("sort-field");
	const sortOrder = searchParams.get("sort-order");

	const [page, setPage] = useState(searchParams.get("page") || DEFAULT_PAGE);
	const [pageSize, setPageSize] = useState(searchParams.get("size") || DEFAULT_PAGE_SIZE);
	const [sortParams, setSortParams] = useState<SortParamsType | null>(
		sortField && sortOrder ? [sortField, sortOrder as "asc" | "desc"] : null,
	);

	const previousQueryStringRef = useRef("");

	const currentQueryString = useMemo(() => {
		const params = new URLSearchParams(location.search);
		params.delete("page");
		return params.toString();
	}, [location.search]);

	const queryString = useMemo(() => {
		const params = new URLSearchParams(location.search);

		if (page.length > 0) {
			params.set("page", page);
		} else {
			params.delete("page");
		}

		if (pageSize.length > 0) {
			params.set("size", pageSize);
		} else {
			params.delete("size");
		}

		if (sortParams && sortParams.length > 0) {
			if (sortParams[0]) {
				params.set("sort-field", sortParams[0]);
			}

			if (sortParams[1]) {
				params.set("sort-order", sortParams[1]);
			}
		} else {
			params.delete("sort-field");
			params.delete("sort-order");
		}

		return decodeURIComponent(params.toString());
	}, [location.search, page, pageSize, sortParams]);

	const { data, refetch, isLoading } = useQuery({
		queryKey: ["dataUsageMonitoringList"],
		queryFn: () => {
			const formatedPage = Number(page) ? Number(page) - 1 : 0;
			const formattedDateFrom = searchParams.get("date-from")
				? getFormattedDateToUTCWithTimezone(
						searchParams.get("date-from") || "",
						DayBoundaryEnum.DAY_START,
					)
				: undefined;
			const formattedDateTo = searchParams.get("date-to")
				? getFormattedDateToUTCWithTimezone(
						searchParams.get("date-to") || "",
						DayBoundaryEnum.DAY_END,
					)
				: undefined;

			return getDataUsageMonitoringList({
				imei: searchParams.get("imei") || undefined,
				device: searchParams.get("device") || undefined,
				application: searchParams.get("application") || undefined,
				datafrom: formattedDateFrom,
				datato: formattedDateTo,
				sortField: sortParams ? sortParams[0] : undefined,
				sortOrder: sortParams && sortParams[1] ? sortParams[1] : undefined,
				page: formatedPage.toString(),
				size: pageSize,
			});
		},
		enabled: true,
	});

	const onPageChange = (page: string) => {
		setPage(page);
	};
	const onPageSizeChange = (size: string) => setPageSize(size);

	const osSortChange = (value: SortParamsType) => {
		setSortParams(value);
	};

	useEffect(() => {
		if (currentQueryString !== previousQueryStringRef.current) {
			setPage(DEFAULT_PAGE);
		}
		previousQueryStringRef.current = currentQueryString;
	}, [currentQueryString]);

	useEffect(() => {
		navigate(`${location.pathname}?${queryString}`, { replace: true });
		refetch();
	}, [queryString, navigate, refetch, location.pathname]);

	return {
		isLoading,
		refetch,
		page,
		setPage,
		onPageChange,
		pageSize,
		onPageSizeChange,
		sortParams,
		osSortChange,
		...(data ?? {}),
	};
};
