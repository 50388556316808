import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const StyledMenuList = styled(Box)`
	width: 100%;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	ul li:not(:first-of-type) {
		margin-top: 16px;
	}
`;
