import type { ICellRendererParams } from "ag-grid-community";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import TimerIcon from "@icons/TimerIcon";

import { StyledActiveTimeRenderer } from "./StyledActiveTimeRenderer";

dayjs.extend(duration);

export const ActiveTimeRenderer = (props: ICellRendererParams) => {
	const activeTimeInSeconds = props.value ? Number(props.value) : 0;
	const formattedActiveTime = dayjs.duration(activeTimeInSeconds, "seconds").format("HH:mm:ss");

	return (
		<StyledActiveTimeRenderer>
			<TimerIcon width="16" height="16" />
			{formattedActiveTime}
		</StyledActiveTimeRenderer>
	);
};
