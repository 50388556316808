import type { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

import cx from "classnames";

import { Icon } from "@components/MenuListItem/Icon/Icon";
import { Label } from "@components/MenuListItem/Label/Label";
import { StyledMenuListItem } from "@components/MenuListItem/StyledMenuListItem";
import Box from "@mui/material/Box";

export type MenuListItemProps = {
	className?: string;
	icon?: ReactNode;
	selectedIcon?: ReactNode;
	children: string;
	href?: string;
	subhrefs?: string[];
	onClick?: () => void;
};

export const MenuListItem = ({
	className,
	icon,
	selectedIcon,
	children,
	href,
	onClick,
	subhrefs = [],
}: MenuListItemProps) => {
	const location = useLocation();
	const isCurrentMenuItem =
		location.pathname === href || subhrefs.find((subhref) => location.pathname.startsWith(subhref));
	const formattedIcon = isCurrentMenuItem ? selectedIcon : icon;

	return (
		<StyledMenuListItem
			className={cx(className, { isCurrentMenuItem: isCurrentMenuItem, isIconExist: icon })}
		>
			{href ? (
				<Link className="menu-list-item__cta" to={href}>
					{formattedIcon && <Icon className="menu-list-item__cta--icon" icon={formattedIcon} />}
					<Label className="menu-list-item__cta--label">{children}</Label>
				</Link>
			) : (
				<Box className="menu-list-item__cta" onClick={onClick}>
					{formattedIcon && <Icon className="menu-list-item__cta--icon" icon={formattedIcon} />}
					<Label className="menu-list-item__cta--label">{children}</Label>
				</Box>
			)}
		</StyledMenuListItem>
	);
};
