import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledSelectPageSizeContainer = styled.div`
	${({ theme }: { theme?: Theme }) => css`
		display: flex;
		position: absolute;
		right: 40px;
		align-items: center;
	`}
`;
