import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledMenuListItem = styled.li`
	${({ theme }: { theme?: Theme }) => css`
		.menu-list-item__cta {
			padding: 16px;
			display: flex;
			gap: 16px;
			border-radius: 32px;
			color: ${theme?.palette?.customColors.blue100};
			cursor: pointer;
			transition: ease 0.3s;
		}

		.menu-list-item__cta:hover {
			background-color: ${theme?.palette?.customColors?.blue600};
		}

		&.isCurrentMenuItem .menu-list-item__cta {
			color: ${theme?.palette?.customColors?.blue200};
			background-color: ${theme?.palette?.customColors?.blue600};
		}

		.menu-list-item__cta--icon {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.menu-list-item__cta--label {
			line-height: ${theme?.customTypography?.lineHeight.lh22};
			letter-spacing: 0;
		}

		&.isCurrentMenuItem .menu-list-item__cta--label {
			font-weight: ${theme?.customTypography?.fontWeight.bold};
		}
	`}
`;
