import { Markers } from "@pages/LocationTracking/LocationTrackingMap/Markers/Markers";
import type { LocationTrackingDeviceType } from "@type/LocationTrackingTypes";
import { APIProvider, Map } from "@vis.gl/react-google-maps";

type MapProps = {
	initialMapCenter: google.maps.LatLngLiteral;
	initialMapZoom: number;
	mapCenter: google.maps.LatLngLiteral;
	mapZoom: number;
	isProgrammaticInteraction: boolean;
	setIsProgrammaticInteraction: (val: boolean) => void;
	devices: LocationTrackingDeviceType[];
	openDevice: string;
	selectedDevice: LocationTrackingDeviceType | null;
	onSelectMapDevice: (val: LocationTrackingDeviceType) => void;
};

const MAP_BOUNDS = {
	north: 85,
	south: -85,
	west: -180,
	east: 180,
};

export const LocationTrackingMap = ({
	initialMapCenter,
	initialMapZoom,
	mapCenter,
	mapZoom,
	isProgrammaticInteraction,
	setIsProgrammaticInteraction,
	devices,
	openDevice,
	selectedDevice,
	onSelectMapDevice,
}: MapProps) => (
	<APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY} language="en">
		<div style={{ width: "100%", height: "100vh" }}>
			<Map
				mapId="location-tracking"
				defaultZoom={initialMapZoom}
				defaultCenter={initialMapCenter}
				zoom={isProgrammaticInteraction ? mapZoom : undefined}
				center={isProgrammaticInteraction ? mapCenter : undefined}
				onZoomChanged={() => {
					setIsProgrammaticInteraction(false);
				}}
				onCenterChanged={() => {
					setIsProgrammaticInteraction(false);
				}}
				fullscreenControl={false}
				mapTypeControl={false}
				streetViewControl={false}
				draggable={true}
				restriction={{
					latLngBounds: MAP_BOUNDS,
					strictBounds: true,
				}}
			>
				<Markers
					devices={devices}
					openDevice={openDevice}
					selectedDevice={selectedDevice}
					onSelectMapDevice={onSelectMapDevice}
				/>
			</Map>
		</div>
	</APIProvider>
);
