import { useLocation } from "react-router-dom";

import { downloadCSV } from "@helpers/downloadCSV";
import { DayBoundaryEnum, getFormattedDateToUTCWithTimezone } from "@helpers/getFormattedDate";
import { useMutation } from "@tanstack/react-query";

import { getDataUsageMonitoringCSV } from "../api/getDataUsageMonitoringCSV";

export const useDataUsageMonitoringCSVExport = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const {
		mutate: onCSVDownload,
		isPending,
		isError,
	} = useMutation({
		mutationFn: () => {
			const formattedDateFrom = searchParams.get("date-from")
				? getFormattedDateToUTCWithTimezone(
						searchParams.get("date-from") || "",
						DayBoundaryEnum.DAY_START,
					)
				: undefined;
			const formattedDateTo = searchParams.get("date-to")
				? getFormattedDateToUTCWithTimezone(
						searchParams.get("date-to") || "",
						DayBoundaryEnum.DAY_END,
					)
				: undefined;

			return getDataUsageMonitoringCSV({
				imei: searchParams.get("imei") || undefined,
				device: searchParams.get("device") || undefined,
				application: searchParams.get("application") || undefined,
				datafrom: formattedDateFrom,
				datato: formattedDateTo,
				sortField: searchParams.get("sort-field") || undefined,
				sortOrder: searchParams.get("sort-order") || undefined,
			});
		},
		onSuccess: (data) => {
			downloadCSV(data, "data-usage-monitoring");
		},
	});

	return { onCSVDownload, isPending, isError };
};
