import type { ReactNode } from "react";

import { Logo, LogoVariantEnum } from "@components/Logo/Logo";
import { StyledAuthLayout } from "@layout/AuthLayout/StyledAuthLayout";
import { Box, Grid } from "@mui/material";

type AuthLayoutProps = {
	children: ReactNode;
	rightColumnContent?: ReactNode;
	bgImageSrc: string;
};

export const AuthLayout = ({ children, bgImageSrc }: AuthLayoutProps) => (
	<StyledAuthLayout className="authLayout" container py={5} pl={12} pr={5}>
		<Grid item xs={6} pr={5} className="authLayout__left">
			<Box className="authLayout__left--inner">
				<Logo variant={LogoVariantEnum.LARGE} />
				<Box mt={23.2}>{children}</Box>
			</Box>
		</Grid>
		<Grid item xs={6} className="authLayout__right">
			<Box className="authLayout__right--body" sx={{ backgroundImage: `url(${bgImageSrc})` }} />
		</Grid>
	</StyledAuthLayout>
);
