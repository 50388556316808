import type { SVGProps } from "react";

const InactiveStatusIcon = ({ width = "32", height = "32", ...rest }: SVGProps<SVGSVGElement>) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<g filter="url(#filter0_f_3411_4752)">
			<circle cx="16" cy="16" r="12" fill="#D0D5DE" />
		</g>
		<circle cx="16" cy="16" r="4" fill="#737C8C" />
		<defs>
			<filter
				id="filter0_f_3411_4752"
				x="0"
				y="0"
				width="32"
				height="32"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
				<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_3411_4752" />
			</filter>
		</defs>
	</svg>
);

export default InactiveStatusIcon;
