import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material/styles";

const getBackgroundColor = (value: string | undefined, theme?: Theme) => {
	if (typeof value === "string") {
		if (value.includes("Android")) {
			return theme?.palette?.customColors.green300;
		} else if (value.includes("iOS")) {
			return theme?.palette?.customColors.purple100;
		}
	}
	return "transparent";
};

export const StyledOSVersionRenderer = styled.div<{ value: string | undefined; theme?: Theme }>`
	${({ theme, value }) => css`
		display: flex;
		align-items: center;
		gap: 8px;
		background-color: ${getBackgroundColor(value, theme)};
		width: 140px;
		padding: 0px 16px;
		border-radius: 50px;
	`}
`;
