import type { ColDef, GridOptions, ITooltipParams } from "ag-grid-community";

import { ActiveTimeRenderer } from "@components/Table/cellRenderers/ActiveTime/ActiveTimeRenderer";
import { NumberCellRenderer } from "@components/Table/cellRenderers/Number/NumberCellRenderer";
import { OSVersionRenderer } from "@components/Table/cellRenderers/OSVersion/OSVersionRenderer";
import { StatusRenderer } from "@components/Table/cellRenderers/Status/StatusRenderer";
import { TimeCellRenderer } from "@components/Table/cellRenderers/Time/TimeCellRenderer";
import { getRowHeight } from "@helpers/getRowHeight";
import {
	defaultComparator,
	memoryCapacityComparator,
	numericStringComparator,
} from "@helpers/tableComparators";
import type { RowDataType, TableSettingsType } from "@type/TableTypes";

export const SUPPORT_LINK = "mailto:support@gmail.com";

export const LOGIN_PAGE_PATH = "/";

export const DASHBOARD_HOME_PAGE = "/user-activity-tracking";
export const LOGIN_SUCCESS_REDIRECT_PATH = "/user-activity-tracking";

export enum LoadingStatusEnum {
	LOADING = "loading",
	LOADED = "loaded",
	ERROR = "error",
	INITIAL = "initial",
}

export const gridSizes = {
	headerHeight: 62,
	rowHeight: 70,
	detailHeaderHeight: 38,
	detailRowHeight: 46,
	detailRowThreshold: 5,
};

const icons = {
	sortUnSort: '<img src="/icons/sort-none.svg" />',
};

const defaultColDef = {
	flex: 1,
	resizable: false,
	suppressMovable: true,
	suppressMenu: true,
	tooltipValueGetter: (params: ITooltipParams) => params.value,
	comparator: defaultComparator,
};

const sharedGridOptions = {
	masterDetail: false,
	enableRangeSelection: false,
	pagination: true,
	paginationAutoPageSize: false,
	suppressContextMenu: true,
	suppressRowClickSelection: true,
	suppressHorizontalScroll: true,
	suppressRowHoverHighlight: true,
	unSortIcon: true,
	suppressCellFocus: true,
	popupParent: document.body,
	tooltipShowDelay: 1000,
	headerHeight: gridSizes.headerHeight,
	rowHeight: gridSizes.rowHeight,
	animateRows: false,
	icons: icons,
	defaultColDef: defaultColDef,
};

const detailGridOptions: GridOptions = {
	...sharedGridOptions,
	pagination: false,
	headerHeight: gridSizes.detailHeaderHeight,
	rowHeight: gridSizes.detailRowHeight,
};

export const TABLE_FOR_PAGE: { [key: string]: TableSettingsType } = {
	USER_ACTIVITY_TRACKING: {
		gridOptions: {
			...sharedGridOptions,
			columnDefs: [
				{ headerName: "IMEI", field: "imei_main", minWidth: 200, sortable: false },
				{ headerName: "Device", field: "device_name", sortable: false },
				{ headerName: "Country", field: "country", minWidth: 180, sortable: false },
				{
					headerName: "Start time",
					field: "start_time",
					minWidth: 240,
					cellRenderer: TimeCellRenderer,
					tooltipValueGetter: () => "Device local time is displayed",
				},
				{
					headerName: "Active time",
					field: "time_of_usage",
					maxWidth: 140,
					cellRenderer: ActiveTimeRenderer,
					comparator: numericStringComparator,
				},
				{ headerName: "Application", field: "application_name", minWidth: 180, sortable: false },
			] as ColDef<RowDataType>[],
		},
	},
	DEVICE_DETAILS_REPORTING: {
		gridOptions: {
			...sharedGridOptions,
			columnDefs: [
				{ headerName: "IMEI", field: "imei_main", sortable: false },
				{ headerName: "Device", field: "device_name" },
				{ headerName: "OS version", field: "os_version", cellRenderer: OSVersionRenderer },
				{
					headerName: "Memory capacity",
					field: "memory_capacity",
					comparator: memoryCapacityComparator,
				},
				{ headerName: "Status", field: "status", cellRenderer: StatusRenderer },
			] as ColDef<RowDataType>[],
		},
	},
	DATA_USAGE_MONITORING: {
		gridOptions: {
			...sharedGridOptions,
			masterDetail: true,
			getRowHeight: getRowHeight,
			getRowStyle: () => ({ cursor: "pointer" }),
			columnDefs: [
				{ headerName: "IMEI", field: "IMEI", sortable: false },
				{ headerName: "Device", field: "device", sortable: false },
				{
					headerName: "Total Data",
					field: "totalData",
					cellStyle: { color: "#1C9B55" },
					cellRenderer: NumberCellRenderer,
					comparator: numericStringComparator,
				},
				{
					headerName: "Last  Uploaded",
					field: "uploadedAt",
					cellRenderer: TimeCellRenderer,
					tooltipValueGetter: () => "Device local time is displayed",
				},
				{
					cellRenderer: "agGroupCellRenderer",
					maxWidth: 48,
					width: 48,
					sortable: false,
					cellStyle: { paddingLeft: 0 },
				},
			] as ColDef<RowDataType>[],
		},
		detailGridOptions: {
			...detailGridOptions,
			columnDefs: [
				{ headerName: "Application", field: "application" },
				{
					headerName: "Wi-Fi",
					field: "wifi",
					comparator: numericStringComparator,
					cellRenderer: NumberCellRenderer,
				},
				{
					headerName: "Mobile data",
					field: "mobileData",
					comparator: numericStringComparator,
					cellRenderer: NumberCellRenderer,
				},
				{
					headerName: "Total data",
					field: "totalData",
					maxWidth: 180,
					comparator: numericStringComparator,
					cellRenderer: NumberCellRenderer,
				},
				{
					headerName: "Last uploaded",
					field: "uploadedAt",
					cellRenderer: TimeCellRenderer,
					tooltipValueGetter: () => "Device local time is displayed",
				},
			],
		},
	},
};

export enum DeviceStatusEnum {
	ACTIVE = "Active",
	INACTIVE = "Inactive",
	MOVING = "Moving",
}

export enum FilterEnum {
	DROPDOWN = "dropdown",
	DATA_PICKER = "dataPicker",
	DATA_PICKER_DATE_AND_TIME = "dataPickerDateAndTime",
}
