import { StyledDeviceMarker } from "@pages/LocationTracking/LocationTrackingMap/CustomCluster/StyledCustomCluster";

type CustomClusterProps = {
	label: string;
};

export const CustomCluster = ({ label }: CustomClusterProps) => {
	const scale = label.length <= 2 ? 35 : 57;
	const size = `${scale * 2}px`;

	return (
		<StyledDeviceMarker
			width={size}
			height={size}
			display="flex"
			alignItems="center"
			justifyContent="center"
		>
			{label}
		</StyledDeviceMarker>
	);
};
