import { Input } from "@components/Input/Input";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";

export const StyledSearch = styled(Input)`
	${({ theme }: { theme?: Theme }) => css`
		.select__search--icon {
			flex-shrink: 0;
			position: relative;
			color: ${theme?.palette?.customColors.black200}!important;
			z-index: 1;
		}
	`}
`;
