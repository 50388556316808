import { axiosInstance } from "@services/axios";

type GetUserActivityTrackingCSVRequestType = {
	imei?: string;
	device?: string;
	country?: string;
	datafrom?: string;
	datato?: string;
	application?: string;
	sortField?: string;
	sortOrder?: string;
};

export const getUserActivityTrackingCSV = async ({
	imei,
	device,
	country,
	application,
	datafrom,
	datato,
	sortField,
	sortOrder,
}: GetUserActivityTrackingCSVRequestType) => {
	const params = new URLSearchParams();
	if (imei) params.append("imei", imei);
	if (device) params.append("device", device);
	if (country) params.append("country", country);
	if (application) params.append("application", application);
	if (datafrom) params.append("datefrom", datafrom);
	if (datato) params.append("dateto", datato);
	if (sortField) params.append("sortField", sortField);
	if (sortOrder) params.append("sortOrder", sortOrder);

	return await axiosInstance
		.get(`page/activity/devices/csv?${params.toString()}`, {})
		.then(({ data }) => data);
};
