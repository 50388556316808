import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import { Grid } from "@mui/material";

export const StyledAuthLayout = styled(Grid)`
	${({ theme }: { theme?: Theme }) => css`
		width: 100%;
		min-height: 100vh;

		.authLayout__left {
			flex: 1;
			display: flex;
			justify-content: center;
		}

		.authLayout__left--inner {
			width: 100%;
			max-width: 506px;
		}

		.authLayout__right {
			display: flex;
			flex-direction: column;
		}

		.authLayout__right--body {
			flex: 1;
			border-top-left-radius: 60px;
			border-bottom-right-radius: 60px;
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}

		.authLayout__right--body.isEmptyContent {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.authLayout__right--footer {
			display: flex;
			justify-content: center;
		}
	`}
`;
