import type { MouseEvent, RefObject } from "react";

import cx from "classnames";

import { StyledToggle } from "@components/Toggle/StyledToggle";
import { Tooltip } from "@components/Tooltip/Tooltip";
import CloseIcon from "@icons/CloseIcon";
import { ExpandMoreIcon } from "@icons/index";
import { Box } from "@mui/material";

export type ToggleProps = {
	className?: string;
	selectedItemsQty?: number;
	selectedDateRange?: string;
	isOpen: boolean;
	innerRef: RefObject<HTMLButtonElement>;
	placeholder: string;
	label?: string;
	onToggle: (e: MouseEvent<HTMLButtonElement>) => void;
	onClear?: () => void;
	isError?: boolean;
};

export const Toggle = ({
	className,
	selectedItemsQty,
	selectedDateRange,
	isOpen,
	innerRef,
	label,
	placeholder,
	onToggle,
	onClear,
	isError,
}: ToggleProps) => {
	const isSelected = !!label && selectedDateRange === undefined;

	const handleClearClick = (e: MouseEvent<HTMLSpanElement>) => {
		e.stopPropagation();
		onClear?.();
	};

	return (
		<StyledToggle
			ref={innerRef}
			className={cx("select__toggle", className, { isOpen, isSelected, isError })}
			onClick={onToggle}
		>
			<div className="select__toggle--inner">
				<div className="select__toggle--value">
					{selectedDateRange ? placeholder : label ?? placeholder}
					{selectedItemsQty && (
						<Box className="select__toggle--dropdown-selected-qty" mx={1} component="span">
							{selectedItemsQty}
						</Box>
					)}
				</div>
				<div className="select__toggle--ctas">
					{selectedDateRange && (
						<Tooltip title={selectedDateRange}>
							<Box className="select__toggle--dropdown-selected-date" ml={1} component="span">
								{selectedDateRange}
							</Box>
						</Tooltip>
					)}
					{selectedDateRange || selectedItemsQty ? (
						<span className="select__toggle--dropdown-indicator" onClick={handleClearClick}>
							<CloseIcon />
						</span>
					) : (
						<span className="select__toggle--dropdown-indicator">
							<ExpandMoreIcon />
						</span>
					)}
				</div>
			</div>
		</StyledToggle>
	);
};
