import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Theme } from "@mui/material";
import { Box } from "@mui/material";

export const StyledProfileCard = styled(Box)`
	${({ theme }: { theme?: Theme }) => css`
		display: inline-flex;
		align-items: center;
		padding: 4px 0;
		gap: 8px;

		.profile-card__avatar {
			width: 48px;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			border-radius: 50%;
			text-transform: uppercase;
			background-color: ${theme?.palette?.customColors.blue600};
		}

		.profile-card__avatar,
		.profile-card__label {
			color: ${theme?.palette?.customColors.blue100};
			line-height: ${theme?.customTypography?.lineHeight.lh22};
			letter-spacing: ${theme?.customTypography?.letterSpacing.ls03};
		}
	`}
`;
