import React, { type ReactNode } from "react";

import { NoSearchResults } from "@components/CustomSelect/NoResults/NoResults";
import { ScrollBar } from "@components/ScrollBar/ScrollBar";
import { List as MuiList } from "@mui/material";

import { StyledList } from "./StyledList";

type SelectListProps = {
	options: string[];
	noOptionsText: string;
	renderItem: (
		className: string,
		option: string,
		isSelected: boolean,
		onClick: (option: string) => void,
	) => ReactNode;
	isMultiple?: boolean;
	selected: string[];
	onSelect: (option: string) => void;
};

export const List = ({
	options,
	renderItem,
	isMultiple,
	selected,
	onSelect,
	noOptionsText,
}: SelectListProps) => (
	<>
		{options.length ? (
			<StyledList className="select__dropdown--list-wrapper">
				<ScrollBar>
					<MuiList className="select__dropdown--list">
						{options.map((option) => (
							<React.Fragment key={option}>
								{renderItem(
									"select__dropdown--list-item",
									option,
									isMultiple
										? selected.some((item) => item === option)
										: selected.length > 0 && selected[0] === option,
									onSelect,
								)}
							</React.Fragment>
						))}
					</MuiList>
				</ScrollBar>
			</StyledList>
		) : (
			<NoSearchResults className="select__dropdown--no-options" label={noOptionsText} />
		)}
	</>
);
